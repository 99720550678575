//播放页
<template>
  <div id="app3">
    <!-- 顶部 -->
    <Top ref="top" :white="$store.state.model.white"></Top>
    <div v-if="$store.state.islogin">
      <div class="play_body">
        <div class="play_body_content">
          <div class="play_body_left">
            <div class="film">
              <!-- <video id="video1">
              <source src="../assets/text/textplay.mp4" type="video/mp4" />
              您的浏览器不支持 HTML5 video 标签。
            </video> -->
              <div
                id="dplayer"
                class="artplayer-app"
                webkit-playsinline="true"
                playsinline="true"
                x-webkit-airplay="allow"
                x5-playsinline
              >
                <div class="play_load" v-if="islogin">
                  <img v-if="ub_index == 3" src="../assets/u/logo1.png" />
                  <img v-else-if="ub_index == 2" src="../assets/bo/logo1.png" />
                  <img v-else src="../assets/img/logo.png" />
                  <span class="guide">{{ guide }}</span>
                </div>
              </div>
              <div
                id="dplayer0"
                class="artplayer-app0"
                webkit-playsinline="true"
                playsinline="true"
                x-webkit-airplay="allow"
                x5-playsinline
              ></div>
              <div class="adver" v-show="adver_dialog && advertising_loading">
                <div
                  v-if="guanggaolist['pc-play-player']"
                  @click="cliguanggao('pc-play-player')"
                  :style="{
                    backgroundImage: 'url(' + guanggaolist['pc-play-player'][0].source + ')',
                  }"
                ></div>
                <img
                  src="../assets/img/close_blue.png"
                  @click="adver_dialog = false"
                  alt="视频详情介绍的下拉开关图标"
                />
              </div>
              <div class="play_advertising_time" v-if="advertising_ready" @click="advertisingClose">
                <span>
                  {{ adver_time }}
                </span>
                <div class="adv_nodes">VIP可关闭广告</div>
                <div class="adv_close"></div>
              </div>
              <div class="pleaseLogin_pc" v-if="!islogin" @click="climustlogin">请先登录</div>
            </div>
            <div class="film_bottom">
              <div class="film_left">
                <!-- 评论 -->
                <div class="film_list" v-if="videolist">
                  <div class="film_icon f_i-1" @click="toComment"></div>
                  <span>{{ videolist.comment }}</span>
                </div>
                <!-- 点赞 -->
                <div class="film_list" v-if="videolist">
                  <div
                    class="film_icon"
                    :class="evaluate_type > 0 ? 'f_i-2-act' : 'f_i-2'"
                    @click="videoevaluate(1)"
                  ></div>
                  <span>{{ videolist.appreciate }}</span>
                </div>
                <!-- 点踩 -->
                <div class="film_list" v-if="videolist">
                  <div
                    class="film_icon"
                    :class="evaluate_type < 0 ? 'f_i-3-act' : 'f_i-3'"
                    @click="videoevaluate(-1)"
                  ></div>
                  <span>{{ videolist.depreciate }}</span>
                </div>
                <!-- 收藏 -->
                <div class="film_list" v-if="videolist">
                  <div
                    class="film_icon"
                    :class="in_favorite ? 'f_i-4-act' : 'f_i-4'"
                    @click="clifavorite"
                  ></div>
                  <span>{{ videolist.favorite }}</span>
                </div>
                <!-- 分享 -->
                <div
                  class="film_list film_share"
                  @mouseenter="shareShow = true"
                  @mouseleave="shareShow = false"
                >
                  <div class="film_icon f_i-share"></div>
                  <span class="film_share-span">分享</span>
                  <transition name="danmuMenu">
                    <div class="play_share" v-show="shareShow">
                      <div class="play_share-title">
                        <a :href="shareList.weibo" target="_blank">
                          <img src="../assets/img/share_weibo.png" />
                          <span>微博</span>
                        </a>
                        <a :href="shareList.qq" target="_blank">
                          <img src="../assets/img/share_qq.png" />
                          <span>QQ</span>
                        </a>
                        <a :href="shareList.qqzone" target="_blank">
                          <img src="../assets/img/share_qqzone.png" />
                          <span>QQ空间</span>
                        </a>
                        <a :href="shareList.twitter" target="_blank">
                          <img src="../assets/img/share_twitter.png" />
                          <span>twitter</span>
                        </a>
                        <a :href="shareList.facebook" target="_blank">
                          <img src="../assets/img/share_facebook.png" />
                          <span>facebook</span>
                        </a>
                      </div>
                      <div class="play_share-bottom">
                        <div class="play_share-left">
                          <span class="play_share-left-tile">将视频贴到博客或论坛</span>
                          <div class="play_share-left-bottom">
                            <span class="play_share-left-dizhi">视频地址:</span>
                            <div class="play_share-input-box">
                              <input v-model="shareUrl" @focus="getInputFocus($event)" />
                              <button
                                v-clipboard:copy="shareUrl"
                                v-clipboard:success="onCopy"
                                v-clipboard:error="onError"
                                id="shareInput"
                                class="play_share-copy"
                              >
                                复制
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="play_share-right">
                          <vue-qr
                            :text="shareUrlErwm"
                            :size="100"
                            :logoScale="0.2"
                            :logoSrc="qrLogo"
                            :logoMargin="5"
                            :margin="10"
                            :logoCornerRadius="1"
                            :correctLevel="3"
                          ></vue-qr>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
                <!-- 视频报错 -->
                <div class="film_list film_list_error" @click="cliplayerror">
                  <div class="film_icon f_i-5"></div>
                  <span>报错反馈</span>
                </div>
                <!-- 手机 -->
                <!-- <div class="film_list">
                <div class="film_icon f_i-6"></div>
                <span>499</span>
              </div> -->
              </div>
              <div class="film_right">
                <div class="fir_list">
                  <!-- 弹幕 -->
                  <div
                    :class="danmuCode ? 'dan_act' : 'dan_default'"
                    class="fil_img dan"
                    @click="changeDanmuCode"
                  ></div>
                  <!-- <div class="fil_img filmore"></div> -->
                  <div class="fil_img fil_input" @mouseleave="danmuMenu = false">
                    <!-- 弹幕颜色菜单 -->
                    <transition name="danmuMenu">
                      <div class="danmu_color_div" v-show="danmuMenu">
                        <span class="danmu_color_div-1">普通用户字体颜色</span>
                        <div class="danmu_color_list-box">
                          <div
                            class="danmu_color_list"
                            v-for="(item, index) in danmucolorDefault"
                            @click="changeDanmuColor(item.color)"
                            :key="index"
                            :class="{ 'danmu_color-act': item.color == danmu.color }"
                            :style="{ background: '#' + item.color }"
                          ></div>
                        </div>
                        <span class="danmu_color_div-1 danmu_color_div-vip">
                          VIP专享字体颜色
                          <img src="../assets/img/video_vip.png" />
                        </span>
                        <div class="danmu_color_list-box">
                          <div
                            class="danmu_color_list"
                            v-for="(item, index) in danmucolorList"
                            @click="changeDanmuColor(item.color)"
                            :key="index"
                            :class="{ 'danmu_color-act': item.color == danmu.color }"
                            :style="{ background: '#' + item.color }"
                          ></div>
                        </div>
                        <div class="danmu_diy">
                          <div class="danmu_jin">#</div>
                          <input
                            v-model="danmu.color"
                            maxlength="6"
                            :disabled="!isvip"
                            class="danmu_diy-input"
                          />
                          <div
                            class="danmu_diy-div"
                            :style="{ background: '#' + danmu.color }"
                          ></div>
                        </div>
                      </div>
                    </transition>
                    <div
                      class="danmu_color"
                      @click="clidanmuSytle"
                      :style="{ backgroundColor: '#' + danmu.color }"
                      @mouseenter="danmuMenu = true"
                    ></div>
                    <input
                      class="danmu-text"
                      maxlength="30"
                      v-model="danmu.text"
                      placeholder="发个弹幕见证当下"
                    />
                    <span
                      :class="{
                        'fil_danmu_button-time': danmubuttonText != '发表',
                        'fil_danmu_button-login': danmubuttonText == '登录',
                      }"
                      class="fil_danmu_button"
                      @click="sendDanmu"
                    >
                      {{ danmubuttonText }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="play_body_right">
            <div
              class="pbr_top"
              v-if="guanggaolist['pc-play-side-top']"
              @click="cliguanggao('pc-play-side-top')"
            >
              <img :src="guanggaolist['pc-play-side-top'][0].source" alt="播放页右侧上面的广告位" />
            </div>
            <div class="pbr_bottom" v-if="guanggaolist['pc-play-side-bottom']">
              <img
                :src="guanggaolist['pc-play-side-bottom'][0].source"
                alt="播放页右侧下面的广告位"
              />
            </div>
          </div> -->
        </div>
      </div>
      <!-- <div
        class="guanggao"
        style="
          height: auto;
          border-radius: 8px;
          margin: 10px auto;
          display: flex;
          background: linear-gradient(316deg, rgb(145, 214, 255) 0%, rgb(20, 155, 236) 100%);
        "
      >
        <div
          direction="none"
          style="
            color: white;
            font-size: x-large;
            font-weight: bold;
            height: 100%;
            margin: 0px 20px;
            padding: 3px 0px;
          "
        >
          最新资源服务器维护中 部分无法播放
        </div>
      </div> -->
      <!-- <div id="stats">{{ textp2p }}</div> -->
      <!-- 管理员模式 -->
      <!-- <div class="admin_mode" v-if="tem && videoLoading">
      <span class="admin_title"></span>
      <div class="features">
        <span @click="setHead" :class="{ cliloading: loadCli }">
          <span v-show="!loadCli">设置片头/时长</span>
          <div class="dots-6" v-show="loadCli"></div>
        </span>
        <span @click="setTail" :class="{ cliloading: loadCli }">
          <span v-show="!loadCli">设置片尾</span>
          <div class="dots-6" v-show="loadCli"></div>
        </span>
      </div>
      <div class="admin_message">
        <transition name="mcenter_2">
          <span v-show="admin_message">提示消息</span>
        </transition>
      </div>
    </div> -->

      <!-- <div
      v-if="videoLoading"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 28px;
      "
    >
      <span>总时长：{{ Math.floor(dp.player.duration) }}</span>
      <p>当前时长：{{ Math.floor(dp.player.currentTime) }}</p>
    </div> -->
      <div class="play_other">
        <!-- 布局左侧 -->
        <div class="play_other-left">
          <div
            class="pol_guanggao"
            :style="{
              backgroundImage: 'url(' + guanggaolist['pc-play-player-below'][0].source + ')',
            }"
            v-if="guanggaolist['pc-play-player-below']"
            @click="cliguanggao('pc-play-player-below')"
          ></div>
          <!-- 集数body -->
          <div class="pol_body" v-if="videolist">
            <h1 class="pol_title">{{ titleText || videolist.title }}</h1>
            <!-- 副标题 -->
            <div class="pol_nodes">
              <!-- 详情 -->
              <div class="poln_details" @click="clickxq">
                <div class="details_before"></div>
                <a>详情</a>
                <div class="pol_after" :class="{ p_180: p_d }"></div>
              </div>
              <!-- 统计 -->
              <!-- <div class="poln_statistics">
              <div class="statistics_before"></div>
              <a>统计</a>
              <div class="pol_after"></div>
            </div> -->
              <!-- 标签 -->
              <div class="poln_label">
                <!-- <div class="label_box"><a>动作</a></div>
              <div class="label_box"><a>爱情</a></div>
              <div class="label_box"><a>谍战</a></div> -->
                <div class="label_box" v-show="videolist.year">
                  <a>{{ videolist.year }}</a>
                </div>
                <div class="label_box" v-show="menutotal[videolist.category_id]">
                  <a>
                    {{ menutotal[videolist.category_id] }}
                  </a>
                </div>
                <div class="label_box" v-show="videolist.region">
                  <a>{{ videolist.region }}</a>
                </div>
                <div class="label_box" v-show="videolist.language">
                  <a>{{ videolist.language }}</a>
                </div>
              </div>
              <!-- 评分 -->
              <div class="poln_score" v-show="videolist.score">
                <span class="score_num" v-if="videolist.score">
                  {{ videolist.score.toFixed(1) }}
                </span>
                <span class="score_unit">分</span>
              </div>
              <!-- 人气 -->
              <div class="poln_pop">
                <div></div>
                <a>{{ videolist.hits }}</a>
              </div>
            </div>
            <!-- 视频详情简介 -->
            <!-- <transition name="mcenter_10"> -->
            <div class="p_description" v-show="p_d">
              <transition name="mcenter_12">
                <img
                  v-show="videolist.pic"
                  :src="videolist.pic ? videolist.pic : ''"
                  :onerror="
                    ub_index == 2
                      ? $store.state.defaultimg_bo
                      : ub_index == 3
                      ? $store.state.defaultimg_u
                      : $store.state.defaultimg
                  "
                  alt="详情的图标"
                />
              </transition>
              <div class="p_descright">
                <span class="p_dec_1">
                  导演：
                  <span class="p_dec_2">{{ videolist.director }}</span>
                </span>
                <span class="p_dec_1">
                  主演：
                  <span class="p_dec_2">{{ videolist.starring }}</span>
                </span>
                <h2 class="p_dec_1 p_dec_a">
                  简介：
                  <span class="p_dec_2">{{ videolist.description }}</span>
                </h2>
              </div>
            </div>
            <!-- </transition> -->
            <!-- 集数集合 -->

            <div class="pol_set_title">
              <a
                class="pst_list"
                :class="{ 'pst_list-act': video_fragmen_act == index }"
                @click="cliSettitle(index)"
                v-for="(item, index) in video_fragment_title"
                :key="index"
              >
                {{ item }}
                <!-- {{ ltindex }}+{{ index }} -->
              </a>
            </div>
            <!-- 集数列表 -->
            <!-- separation 对应的就是每一板块展示多少集 -->
            <div class="pol_set">
              <a
                class="pol_list"
                :class="item.act ? 'pol_list-act' : {}"
                @click="cliPol"
                :href="
                  YS.returnURL(
                    {
                      path: '/play',
                      query: { video_id: videonews.video_id, lid: item.id },
                    },
                    true,
                  )
                "
                v-for="(item, index) in video_fragment_list.slice(
                  video_fragmen_act * separation,
                  (video_fragmen_act + 1) * separation,
                )"
                :key="index"
              >
                {{ item.symbol }}
                <img
                  src="../assets/img/play-ing-bule.gif"
                  v-if="item.act"
                  alt="当前播放集数的动态样式动图"
                />
              </a>
            </div>
          </div>
          <!-- 评论区 -->
          <div class="comment_box">
            <!-- 评论区标题 -->
            <div class="comment_title" id="commentTitle">
              <div class="comment_icon"></div>
              <span>评论区</span>
            </div>
            <!-- 发表评论主体 -->
            <div class="comment_user">
              <!-- 未登录 -->
              <div class="comment_not" v-show="!$store.state.islogin">
                <span>您尚未登录,请登录后发言</span>
                <a class="gol" @click="YS.gotoVip($event, 'open', 'loginReg')">立即登录</a>
              </div>
              <!-- 已经登录 -->
              <div class="comment_not" v-show="$store.state.islogin">
                <textarea
                  maxlength="140"
                  v-model="textcontent"
                  placeholder="请在此处发表评论
注意，以下行为将被封号：严重剧透、发布广告、木马链接、宣传同类网站、辱骂工作人员等。"
                ></textarea>
                <a class="gof" @click="videopostComment">发表</a>
              </div>
            </div>
            <!-- 评论区 -->
            <div class="comment_body">
              <div class="comment_list" v-for="(item, index) in video_comment_list" :key="index">
                <div class="comment_left">
                  <img
                    :src="item.user_avatar ? item.user_avatar : ''"
                    :onerror="$store.state.defaulava"
                    alt="评论区标题图标"
                  />
                </div>
                <div class="comment_right">
                  <!-- 用户名字 -->
                  <div class="comment_name">
                    <a>{{ item.user_nickname }}</a>
                    <!-- <div></div> -->
                  </div>

                  <!-- 评论内容 -->
                  <span class="comment_text">
                    {{ item.content }}
                  </span>
                  <!-- 评论底部组件 -->
                  <div class="comment_bottom">
                    <span class="comment_time">{{ YS.toTime(item.create_time) }}</span>
                    <!-- <div class="comment_good"></div>
                  <span class="comment_goodnum">599</span> -->
                    <!-- <a>回复</a> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 布局右侧 -->
        <div class="play_other-right" v-show="play_video_rank_list">
          <div class="ll-title" v-if="play_video_rank_list">
            <img
              style="height: 32px; width: 32px"
              v-if="ub_index == 3"
              src="../assets/u/list1.png"
              alt="图标"
            />
            <img
              style="height: 32px; width: 32px"
              v-else-if="ub_index == 2"
              src="../assets/bo/list1.png"
              alt="图标"
            />
            <img
              style="height: 32px; width: 32px"
              v-else
              src="../assets/img/list1.png"
              alt="图标"
            />
            <a>相关·排行榜</a>
          </div>
          <Leadranking
            v-if="play_video_rank_list"
            :leadRanking="play_video_rank_list"
          ></Leadranking>
          <!-- 相关剧集 -->
          <div class="related" v-if="related_list">
            <!-- 头部 -->
            <div class="ll-title">
              <img src="../assets/img/list1.png" alt="相关剧集图标" />
              <a>相关剧集</a>
            </div>
            <div class="related_nodes">
              <div class="related_list" v-for="(item, index) in related_list" :key="index">
                <a
                  :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
                  target="_blank"
                >
                  <img
                    :src="item.pic ? item.pic : ''"
                    :onerror="$store.state.defaultimg"
                    alt="相关剧集的视频封面"
                  />
                </a>
                <div class="related_list_text">
                  <a
                    class="rlt_title"
                    :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
                    target="_blank"
                  >
                    {{ item.title }}
                  </a>
                  <div class="poln_label">
                    <div class="label_box" v-show="item.year">
                      <a>{{ item.year }}</a>
                    </div>
                    <div class="label_box" v-show="$store.state.menudist[item.category_id]">
                      <a>
                        {{ $store.state.menudist[item.category_id] }}
                      </a>
                    </div>
                    <div class="label_box" v-show="item.region">
                      <a>{{ item.region }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- WIN 需要开通会员 -->
      <div class="payresult1" v-show="$store.state.showlogin.mustvip">
        <div class="payrbody1">
          <img class="playmustv" src="../assets/img/mustvip.png" alt="需要vip弹窗的主要提示图片" />
          <span>此为VIP会员特权功能，您暂时无法体验哦</span>
        </div>
        <div class="payrbody2">
          <div class="payrbodyflex">
            <a class="payb_1" @click="YS.gotoVip($event, 'close', 'mustvip')">狠心离开</a>
            <a class="payb_2" @click="climustvip">立即购买</a>
          </div>
        </div>

        <img
          class="login-close"
          @click="YS.gotoVip($event, 'close', 'mustvip')"
          src="../assets/img/close.png"
          alt="关闭需要开通会员弹窗"
        />
      </div>
      <!-- WIN 需要登录才能高清 -->
      <div class="payresult1" v-show="$store.state.showlogin.mustlogin">
        <div class="payrbody1">
          <img class="playmustv" src="../assets/img/mustvip.png" alt="需要登录弹窗的主要提示图片" />
          <span>此为登录用户体验功能，您暂时无法体验哦</span>
        </div>
        <div class="payrbody2">
          <div class="payrbodyflex">
            <a class="payb_1" @click="YS.gotoVip($event, 'close', 'mustlogin')">狠心离开</a>
            <a class="payb_2" @click="climustlogin">前往登录</a>
          </div>
        </div>

        <img
          class="login-close"
          @click="YS.gotoVip($event, 'close', 'mustlogin')"
          src="../assets/img/close.png"
          alt="关闭需要登录用户弹窗"
        />
      </div>
      <!-- <div id="dplayer"></div> -->
      <Bottom></Bottom>
    </div>
  </div>
</template>
<script>
// (function (d, s, id) {
//   var js,
//     fjs = d.getElementsByTagName(s)[0];
//   if (d.getElementById(id)) return;
//   js = d.createElement(s);
//   js.id = id;
//   //下面填入一个你的app id，如果还没，请在fb开发者平台注册一个
//   js.src = '//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.8&appId={your app id}';
//   fjs.parentNode.insertBefore(js, fjs);
// })(document, 'script', 'facebook-jssdk');
</script>
<script>
window.myClick = function (event) {
  event;
  that.YS.gotoVip(1, 'open', 'vipReg');
};
let that;
import Top from '../components/Top'; //导航top
// import Bottom from '../components/Bottom'; //导航top
import Leadranking from '../components/Leadranking'; //电影排行榜
// import 'hls.js';
// import dplayer from 'dplayer'; //播放器插件
import Hls from 'cdnbye';
// import Hls from 'hls.js';
import Artplayer from 'artplayer'; //播放器插件
import Bottom from '../components/Bottom.vue';
import artplayerPluginDanmuku from 'artplayer-plugin-danmuku';
import vueQr from 'vue-qr';

export default {
  // inject: ['routerRefresh'], //在子组件中注入在父组件中创建的属性
  components: {
    Top,
    Leadranking,
    Bottom,
    vueQr,
  },
  computed: {
    isvip: () => {
      return that.$store.state.isvip;
    },
    islogin: () => {
      return that.$store.state.islogin;
    },
    // adver_time: () => {
    //   return (
    //     Math.floor(that.advertising_video.player.duration) -
    //     Math.floor(that.advertising_video.player.currentTime)
    //   );
    // },
  },
  name: 'Play',
  data() {
    return {
      first_load: false,
      fraText: '', //集数的文本内容
      qrLogo: require('../assets/img/icon1.png'),
      shareList: {
        weibo: '',
        qq: '',
        qqzone: '',
        twitter: '',
        facebook: '',
      },
      shareShow: false,
      shareUrlErwm: 'https://www.uvod.tv',
      shareUrl: 'https://www.uvod.tv',
      danmubuttonText: '发表',
      danmuCode: true,
      danmuku: [],
      danmuMenu: false, //弹幕菜单
      adver_time: null,
      set_ad: {},
      repeatedly: false, //是否重新刷新过
      auto_adv: true, //是否直接跳过广告
      advertising_video: {},
      advertising_ready: false,
      advertising_loading: false,
      puaseNum: 0, //自动暂停接口次数
      puaseCurrrent: 0, //记录上次暂停的时间
      setpause: null,
      isiPad: false,
      ismac: false,
      admin_message: false,
      videoLoading: false,
      loadCli: false, //点击按钮
      tem: false, //管理员模式
      tem_id: null,
      autoErrorReport: false, //自动报错弹出
      set2: {},
      titleText: '', //标题文本
      guide: '（1/3）准备中，即将获取该视频信息...', //引导提示文字消息
      guideError: '',
      isiOS: false,
      lastListCode: true, //防止重复弹出已经是最后一集的提示
      menutotal: [],
      listqua: [], //分集的清晰度
      cangetqua: true, //是否获取视频播放资源地址
      canplayqi: null,
      qualityName: '标清·360P', //当前选择视频的清晰度
      nowVideoIndex: null, //当前播放在第几个
      ltindex: 0, //分集列表默认选第一个
      listTotal: 0, //分集总集数
      num: 0,
      subtimechange: null,
      locitem: {}, //本地存储信息
      actsubcode: '', //选择字幕的状态记录
      dpok: false,
      video_soruce: {}, //本地存储的视频清晰度/字幕/片头片尾信息
      url: null,
      jumpover: false, //是否跳过片头片尾
      jumpzimu: true,
      separation: 36, //这里填写列表一版显示多少集参数
      p_d: false, //详情显示
      related_list: null, //相关剧集
      textcontent: '', //富文本框
      in_favorite: null, // 是否已收藏[0=否 1=是]
      in_evaluate: null, // 是否已评价(赞或踩)[0=否 1=是]
      evaluate_type: null,
      video_comment_list: [], //评论列表
      video_comment_total: null, //评论总数
      play_video_rank_list: null, //排行榜数据
      rankname: '', //排行榜名字
      guanggaolist: {},
      //接收参数
      videonews: {
        video_id: null, //获取视频的id变量
        video_fragment_id: null, //获取视频的子集id变量
        quality: null, //清晰度
        seek: null, //跳转的时间
      },
      danmu: {
        text: '',
        time: '',
        color: 'FFFFFF',
      },
      qseek: null,
      videolist: {}, //当前视频信息存储
      video_fragment_title: [],
      video_fragment_list: [], // 分集列表
      video_fragmen_act: 0, //当前选中的集数列表
      adver_dialog: false, //广告弹窗
      video: {},
      playIcon: {},
      playnow: false,
      player: null,
      subact: '', //当前字幕选择的标识
      subtitles: [], //字幕列表
      highlight: [], //片头片尾

      options: {
        video: {
          type: 'hls',
          quality: [],
          defaultQuality: 0,
        },
        subtitle: {
          url: '',
          style: {
            fontSize: '36px',
            bottom: '10%',
            // color: '#b7daff',
          },
          encoding: 'utf-8',
          bilingual: true,
          type: 'webvtt',

          // color: 'white',
        },
        pluginOptions: {
          hls: {
            // hls config
          },
        },

        contextmenu: [{}],
      },
      // 后端支持的清晰度[1=标清 2=高清 3=超清 4=蓝光]
      qua: [null, '标清·360P', '高清·480P', '超清·720P', '蓝光·1080P'],
      dp: {},
      playwindowScroll: () => {},
      p2p: {
        p2pConfig: {
          logLevel: false,
          live: false, // set to false in VOD mode
          // Other p2pConfig options provided by CDNBye
          // getStats: function (totalP2PDownloaded, totalP2PUploaded, totalHTTPDownloaded) {
          //   console.log(totalP2PDownloaded);
          //   console.log(totalP2PUploaded);
          //   console.log(totalHTTPDownloaded);
          // },
        },
      },
      //p2p测试
      _totalP2PDownloaded: null,
      _totalP2PUploaded: null,
      _peerId: null,
      _peerNum: null,
      textp2p: '123',
      //弹幕颜色
      danmucolorList: [
        { color: 'FE0302' },
        { color: 'F44336' },
        { color: 'CC0273' },
        { color: '9C27B0' },
        { color: 'FF7204' },
        { color: 'FFAA02' },
        { color: 'FFD302' },
        { color: 'FFFF00' },
        { color: 'A0EE00' },
        { color: '00CD00' },
        { color: '019899' },
        { color: '4266BE' },
        { color: '89D5FF' },
        { color: '222222' },
        { color: '9B9B9B' },
      ],
      danmucolorDefault: [{ color: 'FFFFFF' }],
      ub_index: 1,
    };
  },
  async created() {
    that = this;
    that;
    this.ub_index = window.g.ub_index;
    //判断是否首次进入此页面
    that.repeatedly = sessionStorage.getItem('repeatedly') ? true : false;

    //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    if (that.YS.ismob()) {
      //移动端地址
      that.YS.routetogo({
        path:
          window.g.ub_index == 2
            ? window.g.MOBILE_URL_BO
            : window.g.ub_index == 3
            ? window.g.MOBILE_URL_U
            : window.g.MOBILE_URL + '/mplay',
        query: that.$route.query,
      });
    }
    that.isiPad = /iPad/i.test(navigator.userAgent);
    that.ismac = /Macintosh/i.test(navigator.userAgent);
    this.videonews.video_id = this.$route.query.video_id ? this.$route.query.video_id : 1;
    //分集判断1
    this.videonews.video_fragment_id = this.$route.query.lid ? this.$route.query.lid : null;
    that.qualityName = that.qua[that.videonews.quality];
    that.options.video.defaultQuality = this.videonews.quality - 1;
    this.videonews.seek = this.$route.query.seek ? this.$route.query.seek : null;

    //分享连接生成
    if (this.$route.query.video_id) {
      that.shareUrl =
        window.location.origin +
        window.location.pathname +
        '?video_id=' +
        this.$route.query.video_id;
      if (this.$route.query.lid) that.shareUrl = that.shareUrl + '&lid=' + this.$route.query.lid;
    }
    that.shareUrlErwm = that.shareUrl;
    that.$store.commit('changelogin');
    that.$store.commit('getisvipCode');
    if (!that.$store.state.islogin) {
      that.danmubuttonText = '登录';
    }
    that.locitem = JSON.parse(localStorage.getItem('artplayer_settings')); //获取本地存储信息
    // //VIP没有设置开关片头片尾时，自动开启
    // if (!that.locitem && that.isvip) {
    //   that.locitem = {};
    //   that.locitem.jumpover = true;
    // }
    // if (that.locitem) {
    //   if (that.locitem.danmuColor && that.isvip) that.danmu.color = that.locitem.danmuColor;
    //   if (that.locitem.danmuCode == false) that.danmuCode = that.locitem.danmuCode;
    // }
    that.getAndCheckquer(); //开局获取并检查校验清晰度

    //获取菜单存储
    var menutotalstore = JSON.parse(localStorage.getItem('menutotal'));
    if (menutotalstore && menutotalstore.time > new Date().getTime()) {
      // that.$store.commit('changeEvery', { key: 'menutotal', value: menutotalstore.data });
      that.menutotalhome = menutotalstore.data;
    } else {
      that.menutotal = [];
      let data = await that.api.user.api_categoryhome({});
      data.category_list &&
        data.category_list.forEach((e) => {
          that.$store.commit('changeEvery', {
            key: 'menudist',
            value: e.name,
            key2: e.id,
          });
          if (e.pid == 0) {
            e.list = [];
            that.menutotal.push(e);
          } else {
            that.menutotal.forEach((a, i) => {
              if (a.id == e.pid) {
                that.menutotal[i].list.push(e);
              }
            });
          }
        });

      //存储至本地
      that.YS.savestore('menutotal', that.menutotal, 600);
    }
    // if (localStorage.getItem('tem')) {
    //   that.tem = true;
    //   that.tem_id = parseInt(localStorage.getItem('tem'));
    // }
  },
  beforeMount() {
    that.getvideoinfo(null);

    // that.playwindowScroll = this.YS.fnThrottle(this.windowScroll, 200);
  },
  // destroyed() {
  //   window.removeEventListener('scroll', this.playwindowScroll);
  // },
  mounted() {
    var u = navigator.userAgent;
    that.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    // window.addEventListener('scroll', this.playwindowScroll);
    that.YS.toTop2();
    that.YS.closeallWin(); //关闭所有弹窗
    that.playIcon = document.getElementById('playBtn');
    that.video = document.getElementById('video1');
    // that.api.user.api_videosource(that.videonews).then((data) => {
    //   that.url = data.video_soruce.url; //artplayer
    // });

    // setTimeout(() => {

    that.videocomment(); //获取评论列表
    // 获取广告
    //获取广告缓存
    var guanggaostore = JSON.parse(localStorage.getItem('guanggaolist_play'));
    if (guanggaostore) {
      if (guanggaostore.time > new Date().getTime()) {
        that.guanggaolist = guanggaostore.data;
      }
    }
    that.adall('pc-play-side-top,pc-play-side-bottom,pc-play-player-below,pc-play-player');
    // that.adall('pc-play-side-bottom');
    // that.adall('pc-play-player-below');
    // that.adall('pc-play-player');

    // setTimeout(() => {
    //   that.YS.actgo('pst_list', 'pst_list-act', 0);
    // }, 300);
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query.video_id != from.query.video_id) {
        this.video_id = to.query.video_id;
        // this.init(); //重新加载数据
      }
    },
  },
  methods: {
    // clicopy() {
    //   // let copyInput = document.getElementById('shareInput');
    //   // copyInput.select();
    //   // document.execCommand('Copy'); // 执行浏览器复制命令
    //   window.clipboardData.setData('Text', that.shareUrl);
    // },
    onCopy: function (e) {
      that.$refs.top.messTop = '复制成功!';
      that.$refs.top.mess_show = true;
      setTimeout(() => {
        that.$refs.top.mess_show = false;
      }, 3000);
    },
    onError: function (e) {
      that.$refs.top.messTop = '复制失败!';
      that.$refs.top.mess_show = true;
      setTimeout(() => {
        that.$refs.top.mess_show = false;
      }, 3000);
    },
    cliPol() {
      sessionStorage.removeItem('repeatedly');
    },
    //登录后重新获取信息
    async getloginplay() {
      try {
        if (that.islogin) {
          that.danmubuttonText = '发表';
        }

        let data = await that.api.user.api_videoinfo({ id: that.videonews.video_id });
        if (data) {
          that.in_favorite = data.in_favorite;
          that.in_evaluate = data.in_evaluate;
          that.evaluate_type = data.evaluate_type;
          data.video &&
            data.video.title &&
            (document.getElementsByTagName('title')[0].innerText =
              '《' +
              data.video.title +
              '》' +
              (this.ub_index == 2
                ? '-在线观看-BO影视'
                : this.ub_index == 3
                ? '-在线观看-U影视'
                : '-在线观看-UB影视'));
        }
        that.p_d = true;
      } catch (error) {
        console.log(error);
      }
    },
    //生成分享连接地址
    changeShareList() {
      that.shareList.weibo =
        'http://service.weibo.com/share/share.php?url=' +
        that.shareUrlErwm +
        '&title=' +
        that.titleText +
        '&pic=' +
        that.videolist.pic;
      that.shareList.qq =
        'https://connect.qq.com/widget/shareqq/index.html?url=' +
        that.shareUrlErwm +
        '&title=' +
        that.titleText +
        '&pic=' +
        that.videolist.pic;
      that.shareList.qqzone =
        'https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' +
        that.shareUrlErwm +
        '&title=' +
        that.titleText +
        '&pic=' +
        that.videolist.pic;
      that.shareList.twitter =
        'https://twitter.com/intent/tweet?text=' +
        that.titleText +
        that.shareUrlErwm +
        '&pic=' +
        that.videolist.pic;
      that.shareList.facebook =
        'https://www.facebook.com/share.php?u=' + that.shareUrlErwm + '&t=' + that.titleText;
      // 'https://www.facebook.com/share.php?t=' + that.titleText + '&u=' + that.shareUrlErwm;
      // FB.ui(
      //   {
      //     method: 'share',
      //     href: that.shareUrlErwm, //这里换成你的网址
      //   },
      //   function (response) {
      //     //分享回调
      //   },
      // );
    },
    //获取视频信息
    getvideoinfo() {
      that.api.user
        .api_videoinfo({ id: this.videonews.video_id })
        .then((data) => {
          that.guide = '（2/3）获取信息成功！即将获取资源...';
          that.videolist = data.video;

          that.related_list = data.related_list;
          that.in_favorite = data.in_favorite;
          that.in_evaluate = data.in_evaluate;
          that.evaluate_type = data.evaluate_type;
          if (data.video_fragment_list) {
            var num = Math.ceil(data.video_fragment_list.length / that.separation);
            that.listTotal = num;
            if (num >= 1) {
              // var num2 = num - 1;
              for (let i = 1; i < num; i++) {
                that.video_fragment_title.push(
                  (i - 1) * that.separation + 1 + '-' + i * that.separation,
                );
              }
              that.video_fragment_title.push(
                that.video_fragment_title.length * that.separation +
                  1 +
                  '-' +
                  data.video_fragment_list.length,
              );
            }
          }
          that.video_fragment_list = data.video_fragment_list;
          that.getlead(); //获取排行榜内容

          that.titleText = data.video.title;
          if (that.video_fragment_list) {
            that.fraText = '';
            //分集判断2，如果获取不到地址栏的就取第一个
            if (!that.videonews.video_fragment_id) {
              that.videonews.video_fragment_id = that.video_fragment_list[0].id;
              that.options.video.quality = that.video_fragment_list[0].qualities;
              that.listqua = that.video_fragment_list[0].qualities;
              if (that.videolist.multiple_fragment) {
                that.titleText = that.titleText + ' 第' + that.video_fragment_list[0].symbol + '集';
                that.fraText = ' 第' + that.video_fragment_list[0].symbol + '集';
              }
            } else {
              //获取到了地址栏的
              that.video_fragment_list.forEach((e) => {
                if (e.id == that.videonews.video_fragment_id) {
                  that.options.video.quality = e.qualities;
                  if (that.videolist.multiple_fragment) {
                    that.titleText = that.titleText + ' 第' + e.symbol + '集';
                    that.fraText = ' 第' + e.symbol + '集';
                  }
                }
              });
            }

            document.getElementsByTagName('title')[0].innerText =
              '《' +
              data.video.title +
              '》' +
              that.fraText +
              (this.ub_index == 2
                ? '-在线观看-BO影视'
                : this.ub_index == 3
                ? '-在线观看-U影视'
                : '-在线观看-UB影视');

            //这里获取完需要播放的分集了,下面需要校验不存在用户的清晰度时（非vip想要进入1080p，拦截掉）
            // that.videonews.quality
            if (that.options.video.quality.indexOf(parseInt(that.videonews.quality)) < 0) {
              //不存在时
              for (var i = 4; i > 0; i--) {
                if (that.options.video.quality.indexOf(i) >= 0) {
                  //匹配到了,把可以放的清晰度拿出来
                  //此时获取到了清晰度了，再做权限校验
                  // console.log(i + '存在');
                  // debugger;
                  if (i >= 3 && !that.isvip) {
                    //清晰度大于3而且不是vip的话
                    that.cangetqua = false;
                    // that.YS.gotoVip(1, 'open', 'mustvip');
                    // console.log(i + '>3,不是vip');
                    continue;
                  }
                  if (i == 2 && !that.islogin) {
                    that.cangetqua = false;
                    // that.YS.gotoVip(1, 'open', 'mustlogin');
                    // console.log(i + '=2,不是vip');
                    continue;
                  }
                  //如果都通过以上的校验，那么
                  that.canplayqi = i;
                  that.cangetqua = true;
                  // debugger;

                  break;
                }
              }
              // that.videonews.forEach((e) => {
              //   e;
              //   debugger;
              // });
            } else {
              //存在的话直接赋予
              that.canplayqi = that.videonews.quality;
            }
            that.getdanmuku(); //获取弹幕，需要放在获取分集ID的后面
            that.changeShareList(); //获取分享连接
            //保险的标清
            //清晰度提示
            if (!that.canplayqi) {
              // debugger;
              if (that.options.video.quality.indexOf(1) >= 0) {
                that.canplayqi = 1;
              } else if (that.options.video.quality.indexOf(2) >= 0) {
                that.$refs.top.usepubilDialog({
                  title: '该视频需要登录用户才能播放，请前往登录',
                  code: false,
                });
                that.YS.gotoVip(1, 'open', 'mustlogin');
              }
              // else if (
              //   that.options.video.quality.indexOf(3) >= 0 ||
              //   that.options.video.quality.indexOf(4) >= 0
              // ) {
              //   that.$refs.top.usepubilDialog({
              //     title: '该视频仅支持超清/蓝光清晰度，至少需要VIP用户能播放，请先开通vip',
              //     code: false,
              //   });
              //   clearInterval(that.set2);
              //   that.YS.gotoVip(1, 'open', 'mustvip');
              // }
              // if (!that.canplayqi) {
              //   that.canplayqi = 1;
              // }
            }
            // debugger;
            if (that.canplayqi) {
              that.videonews.quality = that.canplayqi;
              if (!that.islogin) {
                return that.YS.gotoVip(1, 'open', 'mustlogin');
              }
              //获取清晰度
              //一开始进来时
              that.api.user
                .api_videosource(that.videonews)
                .then((data) => {
                  that.guide = '（3/3）获取资源成功！即将载入播放...';

                  if (!that.auto_adv) {
                    let advertising_video = new Artplayer({
                      container: '.artplayer-app0',
                      autoplay: true,
                      moreVideoAttr: {
                        'webkit-playsinline': true,
                        playsInline: true,
                        autoplay: true,
                        muted: that.repeatedly,
                      },
                      // theme: '#149BEC',
                      whitelist: ['*'],
                      loop: false,
                      setting: false,
                      hotkey: false,
                      isLive: true,
                      control: false,
                      // url: require('../assets/text/textplay.mp4'),
                      // url: 'https://15-cdn.savingbox.me/content/zy-qcynd3j-20210217.mp4/index.m3u8',
                      customType: {
                        m3u8: (video, url) => {
                          var hls = new Hls(that.p2p);
                          hls.loadSource(url);
                          hls.attachMedia(video);
                        },
                      },
                      // url: require('../assets/text/guanggao.mp4'),
                    });

                    that.advertising_video = advertising_video;
                    // document.body.addEventListener(
                    //   'mousedown',
                    //   function () {
                    //     var video = document.getElementsByClassName('art-video');
                    //     video[0].muted = false;
                    //   },
                    //   false,
                    // );

                    advertising_video.on('ready', () => {
                      that.advertising_ready = true;
                      // var video = document.getElementsByClassName('art-video');
                      // video[0].muted = false;
                      sessionStorage.setItem('repeatedly', 1);

                      // setTimeout(() => {
                      //   var video = document.getElementsByClassName('art-video');
                      //   video[0].muted = false;
                      //   var soundbutton = document.getElementsByClassName('art-control-volume');
                      //   soundbutton[0].click();
                      //   that.advertising_video.player.volume = 1;
                      // }, 1000);
                      that.set_ad = setInterval(() => {
                        that.adver_time =
                          Math.floor(that.advertising_video.player.duration) -
                          Math.floor(that.advertising_video.player.currentTime);
                      }, 500);
                    }),
                      advertising_video.on('pause', () => {
                        // window.open('https://www.baidu.com');
                        advertising_video.player.play = true;
                      });
                    advertising_video.on('video:ended', function (event) {
                      that.advertising_ready = false;
                      clearInterval(that.set_ad);
                      advertising_video.destroy();
                      that.advertising_loading = true;
                      that.dp.player.play = true;
                    });
                  }

                  //轮询器，拿到接口数据后再初始化
                  var set1 = setInterval(function () {
                    if (
                      that.options.video.quality[0] &&
                      that.url !== null &&
                      that.url !== undefined
                    ) {
                      clearInterval(set1);
                      //0625 这里总结出ipad不能用customType，否则播放加载不成功，一直loading（可能是safari浏览器本身就支持m3u8）
                      //0629 MAC电脑需要装载customType
                      let dp = null;
                      // if (Hls.isSupported() || that.ismac) {
                      dp = new Artplayer({
                        container: '.artplayer-app',
                        url: that.url,
                        // poster: that.videolist.pic,
                        // url: 'http://192.168.1.10/uvod/m3u8/c4baff813ac1437f217f510b37adec22/index.m3u8',
                        video: that.options.video,
                        quality: that.options.video.quality,
                        whitelist: ['*'],
                        subtitle: that.options.subtitle.url === null ? {} : that.options.subtitle,
                        customType:
                          Hls.isSupported() || that.ismac
                            ? {
                                m3u8: (video) => {
                                  var hls = new Hls(that.p2p);
                                  hls.loadSource(that.url);
                                  hls.attachMedia(video);
                                  hls.on(Hls.Events.ERROR, function (event, data) {
                                    if (!that.first_load) {
                                      if (data.details && data.details == 'manifestLoadError') {
                                        that.$refs.top.usepubilDialog({
                                          title: '因网站数数据迁移，本资源暂时无法观看',
                                          failbutton: 'ok',
                                          code: false,
                                        });
                                      } else {
                                        that.$refs.top.usepubilDialog({
                                          title: '播放错误',
                                          failbutton: 'ok',
                                          code: false,
                                        });
                                      }
                                    }
                                  });
                                  hls.on(Hls.Events.MANIFEST_LOADED, function (event, data) {
                                    that.first_load = true;
                                    that.domClassAdd();
                                  });
                                },
                              }
                            : {},
                        moreVideoAttr: {
                          crossOrigin: 'anonymous',
                          muted: that.repeatedly,
                        },
                        autoSize: true,
                        subtitleOffset: true,
                        setting: true,
                        loop: false,
                        aspectRatio: true,
                        pip: true,
                        fullscreen: true,
                        // fullscreenWeb: true,
                        light: true,
                        hotkey: true,
                        autoplay: that.auto_adv,
                        volume: 0.6,
                        // miniProgressBar: true,
                        // localSubtitle: true,
                        // playbackRate: true,
                        icons: {},
                        highlight: that.highlight, //进度条
                        //自定义添加
                        controls: [
                          {
                            name: 'backR',
                            position: 'right',
                            // html: '倍数<div class="clear_vip_d"></div>',
                            html: '倍数',
                            index: 15,
                            selector: [
                              {
                                name: '2倍',
                                type: 2,
                                index: 0,
                              },
                              {
                                name: '1.5倍',
                                type: 1.5,
                                index: 1,
                              },
                              {
                                name: '1.25倍',
                                type: 1.25,
                                index: 2,
                              },
                              {
                                name: '正常',
                                type: 1,
                                index: 3,
                              },
                              {
                                name: '0.75倍',
                                type: 0.75,
                                index: 4,
                              },
                              {
                                name: '0.5倍',
                                type: 0.5,
                                index: 5,
                              },
                            ],
                            onSelect: function (item) {
                              dp.player.playbackRate = item.type;
                              that.changeBeishu(item.index);
                              dp.storage.set('backRate', item.type);
                              dp.storage.set('backRate_index', item.index);
                              //判断是否二倍
                              // if (item.type == 2) {
                              //   if (that.isvip) {
                              //     dp.player.playbackRate = item.type;
                              //     that.changeBeishu(item.index);
                              //     dp.storage.set('backRate', item.type);
                              //     dp.storage.set('backRate_index', item.index);
                              //   } else if (!that.isvip) {
                              //     that.YS.gotoVip(1, 'open', 'mustvip');
                              //     var www =
                              //       document.getElementsByClassName('art-control-backR')[0]
                              //         .childNodes[0];
                              //     www.textContent = '倍数';
                              //     that.changeBeishu(3);
                              //     // dp.player.playbackRate = 1;
                              //   }
                              // } else {
                              //   dp.player.playbackRate = item.type;
                              //   that.changeBeishu(item.index);
                              //   dp.storage.set('backRate', item.type);
                              //   dp.storage.set('backRate_index', item.index);
                              // }
                              // let videoPlay = document.getElementsByClassName('art-video')[0];
                              // videoPlay.playbackRate = 15;
                            },
                          },
                          // {
                          //   name: 'jumpTail',
                          //   position: 'right',
                          //   html: '跳片头尾',
                          //   selector: [
                          //     { name: '开启', type: true },
                          //     { name: '关闭', type: false },
                          //   ],
                          //   index: 7,
                          //   onSelect: function (item) {
                          //     let jumpDom = document
                          //       .getElementsByClassName('art-control-jumpTail')[0]
                          //       .getElementsByClassName('art-selector-value')[0];
                          //     if (item.type == true) {
                          //       jumpDom.innerText = '跳片头尾：开启';
                          //       // tiaoinputdom.classList.add('buleback');
                          //     } else if (item.type == false) {
                          //       jumpDom.innerText = '跳片头尾：关闭';
                          //       // tiaoinputdom.classList.remove('buleback');
                          //     }
                          //     // else if (item.type == true && !that.isvip) {
                          //     //   that.YS.gotoVip(1, 'open', 'mustvip');
                          //     //   dp.storage.set('jumpover', false);
                          //     // }
                          //     that.changejump2(dp, item.type);
                          //   },
                          //   mounted: () => {
                          //     let jumpDom = document
                          //       .getElementsByClassName('art-control-jumpTail')[0]
                          //       .getElementsByClassName('art-selector-value')[0];
                          //     if (that.jumpover == true) {
                          //       jumpDom.innerText = '跳片头尾：开启';
                          //       // tiaoinputdom.classList.add('buleback');
                          //     } else if (that.jumpover == false) {
                          //       jumpDom.innerText = '跳片头尾：关闭';
                          //       // tiaoinputdom.classList.remove('buleback');
                          //     }
                          //     // else if (that.jumpover == true && !that.isvip) {
                          //     //   that.YS.gotoVip(1, 'open', 'mustvip');
                          //     //   dp.storage.set('jumpover', false);
                          //     // }
                          //   },
                          // },
                          {
                            name: 'nextP',
                            position: 'left',
                            html: '',
                            selector: [],
                            index: 15,
                            onSelect: function () {},
                          },
                        ],
                        //弹幕
                        plugins: [
                          artplayerPluginDanmuku({
                            // danmuku: 'https://artplayer.org/assets/sample/danmuku.xml',
                            danmuku: that.danmuku,
                            speed: 8,
                            maxlength: 50,
                            margin: [0, 200],
                            opacity: 1,
                            fontSize: 25,
                            synchronousPlayback: false,
                          }),
                        ],
                      });
                      // } else {
                      //   dp = new Artplayer({
                      //     container: '.artplayer-app',
                      //     url: that.url,
                      //     // url: 'http://192.168.1.10/uvod/m3u8/c4baff813ac1437f217f510b37adec22/index.m3u8',
                      //     video: that.options.video,
                      //     quality: that.options.video.quality,
                      //     whitelist: ['*'],
                      //     subtitle: that.options.subtitle.url === null ? {} : that.options.subtitle,

                      //     moreVideoAttr: {
                      //       crossOrigin: 'anonymous',
                      //       muted: that.repeatedly,
                      //     },
                      //     autoSize: true,
                      //     subtitleOffset: true,
                      //     setting: true,
                      //     loop: true,
                      //     aspectRatio: true,
                      //     pip: true,
                      //     fullscreen: true,
                      //     // fullscreenWeb: true,
                      //     light: true,
                      //     hotkey: true,
                      //     autoplay: that.auto_adv,
                      //     volume: 0.6,
                      //     // miniProgressBar: true,
                      //     // localSubtitle: true,
                      //     // playbackRate: true,
                      //     icons: {},
                      //     highlight: that.highlight, //进度条
                      //     //自定义添加
                      //     controls: [
                      //       {
                      //         name: 'backR',
                      //         position: 'right',
                      //         // html: '倍数<div class="clear_vip_d"></div>',
                      //         html: '倍数',
                      //         index: 15,
                      //         selector: [
                      //           {
                      //             name: '2倍',
                      //             type: 2,
                      //             index: 0,
                      //           },
                      //           {
                      //             name: '1.5倍',
                      //             type: 1.5,
                      //             index: 1,
                      //           },
                      //           {
                      //             name: '1.25倍',
                      //             type: 1.25,
                      //             index: 2,
                      //           },
                      //           {
                      //             name: '正常',
                      //             type: 1,
                      //             index: 3,
                      //           },
                      //           {
                      //             name: '0.75倍',
                      //             type: 0.75,
                      //             index: 4,
                      //           },
                      //           {
                      //             name: '0.5倍',
                      //             type: 0.5,
                      //             index: 5,
                      //           },
                      //         ],
                      //         onSelect: function (item) {
                      //           //判断是否二倍
                      //           if (item.type == 2) {
                      //             if (that.isvip) {
                      //               dp.player.playbackRate = item.type;
                      //               that.changeBeishu(item.index);
                      //               dp.storage.set('backRate', item.type);
                      //               dp.storage.set('backRate_index', item.index);
                      //             } else if (!that.isvip) {
                      //               that.YS.gotoVip(1, 'open', 'mustvip');
                      //               var www = document.getElementsByClassName(
                      //                 'art-control-backR',
                      //               )[0].childNodes[0];
                      //               www.textContent = '倍数';
                      //               that.changeBeishu(3);
                      //               // dp.player.playbackRate = 1;
                      //             }
                      //           } else {
                      //             dp.player.playbackRate = item.type;
                      //             that.changeBeishu(item.index);
                      //             dp.storage.set('backRate', item.type);
                      //             dp.storage.set('backRate_index', item.index);
                      //           }
                      //         },
                      //       },
                      //       {
                      //         name: 'nextP',
                      //         position: 'left',
                      //         html: '',
                      //         selector: [],
                      //         index: 15,
                      //         onSelect: function () {},
                      //       },
                      //     ],
                      //   });
                      // }
                      that.dp = dp;

                      setTimeout(() => {
                        // console.log(dp.player);
                        // var abc = document.getElementsByClassName('art-video')[0];
                        // abc.click();
                        // dp.player.pause = true;
                        // window.waitFor(1000);
                        if (that.ismac) {
                          dp.loading.show = false;
                          dp.player.play = true;
                        }
                        that.adver_dialog = false;
                      }, 900);

                      // hls.attachMedia(video);
                      //跳转到指定时间
                      // dp.seek(that.videonews.seek);
                      // var videodom = document.getElementsByClassName('artplayer-app')[0];
                      // if (Hls.isSupported()) {
                      //   var hls = new Hls({
                      //     p2pConfig: {
                      //       logLevel: false,
                      //       live: false, // set to false in VOD mode
                      //       // Other p2pConfig options provided by CDNBye
                      //     },
                      //   });
                      // hls.loadSource(that.url);
                      // hls.attachMedia(videodom);
                      // hls.on(Hls.Events.MANIFEST_PARSED, function () {
                      //   video.play();
                      // });
                      //   hls.p2pEngine.on(
                      //     'stats',
                      //     function ({ totalHTTPDownloaded, totalP2PDownloaded, totalP2PUploaded }) {
                      //       var total = totalHTTPDownloaded + totalP2PDownloaded;
                      //       that.textp2p = `p2p ratio: ${Math.round(
                      //         (totalP2PDownloaded / total) * 100,
                      //       )}%, saved traffic: ${totalP2PDownloaded}KB, uploaded: ${totalP2PUploaded}KB`;
                      //     },
                      //   );
                      // }
                      // hls.p2pEngine
                      //   .on('stats', function (stats) {
                      //     that._totalP2PDownloaded = stats.totalP2PDownloaded;
                      //     that._totalP2PUploaded = stats.totalP2PUploaded;
                      //     console.log(stats);
                      //     that.updateStats();
                      //   })
                      //   .on('peerId', function (peerId) {
                      //     that._peerId = peerId;
                      //   })
                      //   .on('peers', function (peers) {
                      //     that._peerNum = peers.length;
                      //     that.updateStats();
                      //   });

                      // else if (videodom.canPlayType('application/vnd.apple.mpegurl')) {
                      //   videodom.src = that.url;
                      // }
                      dp.on('ready', () => {
                        // clearInterval(that.set2); //倒计时报错反馈
                        if (that.autoErrorReport) {
                          that.YS.gotoVip(1, 'close', 'playerror'); //关闭报错反馈
                          that.autoErrorReport = false;
                        }
                        that.resque();
                        //开启跳过片头片尾功能时
                        that.jumptitle(dp);

                        //显示隐藏字幕**********************
                        dp.setting.add({
                          html:
                            '<span>开启关闭字幕： </span><div id="zimusetting"><input type="range" max="1" style="width:40px;" id="zimuinput" /></div>',
                          name: 'zimu',
                          index: 16,
                          click: () => {
                            that.changezimu(dp);
                          },
                        });
                        var zimuinput = document.getElementById('zimuinput');
                        var playvideo = document.getElementsByClassName('art-video-player')[0];
                        //跟据jumpzimu为设置中的input初始化
                        if (that.jumpzimu == true) {
                          zimuinput.value = '1';
                          zimuinput.classList.add('buleback');
                          playvideo.classList.add('art-subtitle-show');
                        } else if (that.jumpzimu == false) {
                          playvideo.classList.remove('art-subtitle-show');
                          zimuinput.classList.remove('buleback');
                          zimuinput.value = '0';
                        } else if (that.jumpzimu == undefined) {
                          zimuinput.value = '1';
                          zimuinput.classList.add('buleback');
                        }

                        //显示隐藏字幕**********************

                        //选择字幕*********************************
                        var hstr = '<div class="zimubutton" id="zimubuttonid">';
                        if (that.subtitles) {
                          that.subtitles.forEach((e) => {
                            var htmltest =
                              '<button class="butsubtitles" data-url=' +
                              e.url +
                              ' data-symbol=' +
                              e.symbol +
                              ' value=' +
                              e.symbol +
                              '>' +
                              e.label +
                              '</button>';
                            hstr = hstr + htmltest;
                          });
                        }
                        hstr = hstr + '</div>';
                        dp.setting.add({
                          html: hstr,
                          name: 'changezimu',
                          index: 17,
                        });
                        var subtitlesdiv = document.getElementById('zimubuttonid');
                        //添加点击事件
                        subtitlesdiv.addEventListener('click', (e) => {
                          if (e.target.dataset.symbol) {
                            dp.subtitle.switch(e.target.dataset.url);
                            dp.storage.set('actsubcode', e.target.dataset.symbol);
                            that.actsubcode = e.target.dataset.symbol;
                            setTimeout(() => {
                              that.changesubtitles();
                            });
                          }
                        });
                        setTimeout(() => {
                          that.changesubtitles();
                        });
                        //选择字幕*********************************

                        //字幕偏移时间********************************

                        dp.setting.add({
                          html:
                            '<span>字幕偏移时间:&nbsp;<span id="pianyitime" class="pianyitime">0</span>s</span><div id="subtimediv" class="subtimediv"><button data-type="less" class="subtime_lr subtime_left">-0.5s</button><button data-type="add"  class="subtime_lr subtime_right">+0.5s</button></div>',
                          name: 'subtime',
                          index: 18,
                        });
                        var subtimediv = document.getElementById('subtimediv');
                        var pianyitime = document.getElementById('pianyitime');
                        subtimediv.addEventListener('click', (e) => {
                          if (e.target.dataset.type) {
                            var type = e.target.dataset.type;
                            if (type == 'add') {
                              that.subtimechange += Number(0.5);
                            }
                            if (type == 'less') {
                              that.subtimechange -= Number(0.5);
                            }
                            dp.plugins.subtitleOffset.offset(that.subtimechange);
                            dp.notice.show = '字幕偏移  ' + that.subtimechange + ' 秒';
                            pianyitime.textContent = that.subtimechange;
                          }
                        });
                        //字幕偏移时间********************************

                        //跳过片头***************************
                        // if (!!that.video_soruce.front_duration) {
                        // var d =
                        //   '<span>跳过片头片尾： </span><div class="clear_vip_q"></div><div id="rangdiv"><input type="range" max="1" style="width:40px;" id="tiaoinput" /></div>';
                        // dp.setting.add({
                        //   html: d,
                        //   name: 'tiao',
                        //   index: 19,
                        //   click: () => {
                        //     that.changejump(dp);
                        //   },
                        //   mounted: () => {
                        //     var tiaoinputdom = document.getElementById('tiaoinput');
                        //     if (that.jumpover == true && that.isvip) {
                        //       tiaoinputdom.value = '1';
                        //       tiaoinputdom.classList.add('buleback');
                        //     } else if (that.jumpover == false) {
                        //       tiaoinputdom.value = '0';
                        //       tiaoinputdom.classList.remove('buleback');
                        //     } else if (that.jumpover == true && !that.isvip) {
                        //       that.YS.gotoVip(1, 'open', 'mustvip');
                        //       dp.storage.set('jumpover', false);
                        //     }
                        //   },
                        // });

                        // }

                        //跳过片头***************************
                        // //选择字幕*********************************

                        // var hstr = '<span>选择字幕： </span><select id="zmselect">';
                        // var hstrnone = '<span>选择字幕： </span><select disabled="disabled" id="zmselect">';
                        // if (that.subtitles) {
                        //   that.subtitles.forEach((e) => {
                        //     var htmltest =
                        //       '<option class="optsub" value="' + e.symbol + '">' + e.label + '</option>';
                        //     hstr = hstr + htmltest;
                        //   });
                        // } else {
                        //   hstr = hstrnone + '<option>暂无选择</option>';
                        // }
                        // hstr = hstr + '</select>';

                        // dp.setting.add({
                        //   html: hstr,
                        //   name: 'changezimu',
                        //   index: 19,
                        // });
                        // var c = document.getElementById('zmselect');
                        // if (that.subact) {
                        //   c.value = that.subact;
                        // }
                        // c.addEventListener('change', () => {
                        //   that.subtitles.forEach((e) => {
                        //     if (e.symbol == c.value) {
                        //       dp.subtitle.switch(e.url);
                        //       dp.storage.set('actsubcode', e.symbol);
                        //     }
                        //   });
                        // });

                        // //选择字幕*********************************
                        //========================================
                        //跳过片尾*********************************
                        that.jumptail(dp);
                        that.dp = dp;

                        //跳过片尾*********************************
                        //========================================

                        that.domClassAdd();
                        that.autoNextSet(dp); //自动下一集
                        that.guide = '视频准备就绪！';
                        //倍数播放
                        if (localStorage.getItem('artplayer_settings')) {
                          try {
                            var localP = JSON.parse(localStorage.getItem('artplayer_settings'));

                            if (localP.backRate && localP.backRate > 0) {
                              that.dp.player.playbackRate = localP.backRate;
                            }
                          } catch (error) {
                            console.log(error.message);
                          }
                        }
                        that.videoLoading = true;

                        that.setpause = setInterval(() => {
                          if (that.dp.player.playing) {
                            that.puaseTime();
                            that.puaseNum += 1;
                          }
                          if (that.puaseNum > 240) clearInterval(that.setpause);
                        }, 1000 * 30);
                        sessionStorage.setItem('repeatedly', 2);

                        //-----------ready-end------------
                      });
                      document.getElementById('topout_pc').innerHTML = that.titleText;
                      // //切换倍数时校验权限
                      // dp.on('playbackRate', () => {
                      //   if (!that.isvip) {
                      //     that.YS.gotoVip(1, 'open', 'mustvip');
                      //     var www = document.getElementsByClassName('art-control-backR')[0].childNodes[0];
                      //     www.textContent = '倍数';
                      //     dp.player.playbackRate = 1;
                      //   }
                      // });
                      //暂停时
                      dp.on('pause', function () {
                        that.adver_dialog = true;

                        that.puaseTime();

                        //间隔两秒则自动隐藏
                      });

                      //播放时
                      dp.on('play', function () {
                        that.adver_dialog = false;
                      });
                      dp.on('quality_end', function () {
                        // dp.play();
                        var qualitymask = document.getElementsByClassName('dplayer-quality-mask');
                        qualitymask[0].classList.add('disnone');
                      });

                      dp.on('selector', function (item) {
                        // console.info(item);
                      });
                      // dp.on('control', () => {
                      //   if (dp.controls.show) {
                      //     !dp.layers.show && (dp.layers.show = true);
                      //   } else {
                      //     dp.layers.show && (dp.layers.show = false);
                      //   }
                      // });

                      //*************************************************************************** */
                      // }, 300);
                      // setTimeout(() => {}, 2000);
                    }
                  }, 300);

                  // if (that.islogin) that.openAutoErrorReport(); //开始计时，然后自动弹出报错反馈
                  that.islogin
                    ? localStorage.removeItem('not_logged_history')
                    : that.YS.setHistory(that.videolist); //未登录下记录缓存
                  that.video_soruce = data.video_soruce;
                  //装载
                  that.url = data.video_soruce.url;
                  // that.url ='https://lmbsy.qq.com/f0246xo7xwe.p201.1.mp4?sdtfrom=&platform=10201&level=0&fmt=shd&vkey=317AB7AA48C2A8D4BB9167FBEC55D481863F1F3C0D06DFAA4D7C199475ECD171183051FE88BCE2E19C30583DBCE3C59353C1F98BBFC78ADBCBB6D45232F5B57ED79CD292F6D515909FA8B7D65C3A0B36442CCB76855831AC4B176B39D758560991BDC1588978FB07F9A452B65FE7EB2CDCD6723AC9B2B7BD';
                  // that.url = require('../assets/text/movie.mp4');
                  if (!data.video_soruce.url) that.guideError += '视频url出错！';
                  //  ---start--- 往进度条插入片头片尾提示
                  data.video_soruce.front_duration &&
                    that.highlight.unshift({
                      time: data.video_soruce.front_duration,
                      text: '片头结束',
                    });
                  data.video_soruce.front_start_duration &&
                    that.highlight.unshift({
                      time: data.video_soruce.front_start_duration,
                      text: '片头开始',
                    });
                  data.video_soruce.tail_duration &&
                    that.highlight.push({
                      time: data.video_soruce.tail_duration,
                      text: '片尾',
                    });
                  data.video_soruce.tail_end_duration &&
                    that.highlight.push({
                      time: data.video_soruce.tail_end_duration,
                      text: '片尾结束',
                    });
                  //  ---end--- 往进度条插入片头片尾提示
                  that.subtitles = data.subtitles;
                  //获取本地存储的跳过片头片尾状态//获取本地存储的选择字幕状态
                  // if (that.islogin && that.isvip) {
                  //   let artplayer_settings = {};
                  //   artplayer_settings.jumpover = true;
                  //   if (!that.locitem) {
                  //     localStorage.setItem(
                  //       'artplayer_settings',
                  //       JSON.stringify(artplayer_settings),
                  //     );
                  //   }
                  // }
                  if (that.locitem) {
                    that.jumpover =
                      that.locitem.jumpover != undefined ? that.locitem.jumpover : false;

                    that.jumpzimu =
                      that.locitem.jumpzimu != undefined ? that.locitem.jumpzimu : true;
                    that.actsubcode = that.locitem.actsubcode ? that.locitem.actsubcode : '';
                  }

                  //遍历循环查找默认的字幕
                  if (data.subtitles) {
                    if (that.actsubcode) {
                      //遍历查找是否存在储存的字幕

                      that.subtitles.forEach((e) => {
                        if (e.symbol == that.actsubcode) {
                          that.options.subtitle.url = e.url;
                          that.subact = e.symbol;
                        }
                      });
                    }
                    if (!that.subact) {
                      that.subtitles.forEach((e) => {
                        if (e.default == 1) {
                          that.options.subtitle.url = e.url;
                          that.subact = e.symbol;
                        }
                      });
                    }
                  }

                  //循环遍历清晰度事件
                  if (data.qualities) {
                    let quaaaa = [];
                    for (let i = 1; i <= 4; i++) {
                      if (data.qualities.indexOf(i) >= 0) {
                        quaaaa.push(i);
                      }
                    }
                    data.qualities = quaaaa;

                    data.qualities.forEach((e, i) => {
                      that.options.video.quality[i] = {};
                      that.options.video.quality[i].name = that.qua[e];
                      if (e == data.video_soruce.quality) {
                        //如果是当前的视频清晰度地址
                        that.options.video.quality[i].url = data.video_soruce.url;

                        // that.options.video.quality[i].url = require('../assets/text/textplay.mp4');
                        // that.options.video.quality[i].url = require('../assets/video/weixiao.mp4');
                        that.options.video.quality[i].type = 'auto';
                        that.options.video.url = data.video_soruce.url;
                      } else {
                        //如果是不是当前的视频清晰度地址
                        that.options.video.quality[i].url = data.video_soruce.url;
                        // that.options.video.quality[i].url = require('../assets/text/textplay.mp4');
                        // that.options.video.quality[i].url = require('../assets/video/weixiao.mp4');
                        that.options.video.quality[i].type = 'auto';
                      }
                    });
                  }
                  that.qualityName = that.qua[data.video_soruce.quality];
                  //记录清晰度到缓存
                  if (localStorage.getItem('artplayer_settings')) {
                    var localItem = JSON.parse(localStorage.getItem('artplayer_settings'));
                    localItem.qialityloc = data.video_soruce.quality;
                    localStorage.setItem('artplayer_settings', JSON.stringify(localItem));
                  }
                  that.videonews.quality = data.video_soruce.quality;
                  // } else {
                  //   var localQia = {};
                  //   localQia.quality = data.video_soruce.quality;
                  //   localStorage.setItem('artplayer_settings', JSON.stringify(localQia));
                  // }
                })
                .catch((error) => {
                  if (error.message !== "Cannot read property 'subtitle' of null") {
                    that.$refs.top.usepubilDialog({ title: error.message, code: false });
                    that.guideError += 'api_videosource接口阶段出错';
                  }
                });
            }
            //计算出当前应该选第几个列表
            this.video_fragment_list.forEach((e, i) => {
              if (e.id == that.videonews.video_fragment_id) {
                e.act = true;
                that.nowVideoIndex = i + 1;
                setTimeout(() => {
                  that.num = Math.floor(that.listTotal / (that.separation + 0.1));
                  that.video_fragmen_act = Math.floor(that.nowVideoIndex / (that.separation + 0.1));
                  that.videonews.video_fragment_id;
                  // that.YS.actgo('pst_list', 'pst_list-act', num);
                  // that.video_fragmen_act = that.num;
                }, 300);
              }
            });
            that.p_d = true;
          }
        })
        .catch((error) => {
          that.guideError += 'videoinfo接口阶段出错！';
          that.$refs.top.usepubilDialog({ title: error.message, code: false });
        });
    },
    //获取弹幕
    async getdanmuku() {
      try {
        let data = await that.api.user.api_videoBarragelist({
          video_id: that.videonews.video_id,
          video_fragment_id: that.videonews.video_fragment_id,
        });
        // let data2 = await that.api.user.api_videoBarragepostBarrage({
        //   video_id: that.videonews.video_id,
        //   video_fragment_id: that.videonews.video_fragment_id,
        //   elapsed: 1,
        // });

        if (!data || !data.videoBarrage_list) return;
        that.danmuku = data.videoBarrage_list;
      } catch (error) {
        console.log(error.message);
      }
    },
    //发送弹幕
    async sendDanmu() {
      try {
        if (that.danmubuttonText == '登录') {
          that.YS.gotoVip(1, 'open', 'loginReg');
          that.$refs.top.TOvercode(); //获取验证码
          return;
        }
        if (that.danmubuttonText != '发表') return;
        that.danmubuttonText = '...';
        let danmuthis = {};
        danmuthis.text = that.danmu.text;
        danmuthis.time = Math.floor(that.dp.player.currentTime) + 4;
        (danmuthis.color = '#' + that.danmu.color), (danmuthis.border = true);
        await that.api.user.api_videoBarragepostBarrage({
          video_id: that.videonews.video_id,
          video_fragment_id: that.videonews.video_fragment_id,
          elapsed: Math.floor(that.dp.player.currentTime),
          content: that.danmu.text,
          color: '#' + that.danmu.color,
        });
        that.dp.plugins.artplayerPluginDanmuku.emit(danmuthis);
        that.danmu.text = '';

        //
        that.danmubuttonText = 5;
        var danmuTime = setInterval(() => {
          that.danmubuttonText -= 1;
          if (that.danmubuttonText == 0) {
            that.danmubuttonText = '发表';
            clearInterval(danmuTime);
          }
        }, 1001);
        if (that.isvip) {
          that.dp.storage.set('danmuColor', that.danmu.color);
        }
      } catch (error) {
        that.$refs.top.usepubilDialog({ title: error.message, code: false });
        that.danmubuttonText = '发表';
      }
    },
    //弹幕开关
    async changeDanmuCode() {
      try {
        that.danmuCode = !that.danmuCode;
        if (that.danmuCode) {
          that.dp.plugins.artplayerPluginDanmuku.show();
        } else {
          that.dp.plugins.artplayerPluginDanmuku.hide();
        }
        that.dp.storage.set('danmuCode', that.danmuCode);
      } catch (error) {
        console.log(error.message);
      }
    },
    //改变弹幕颜色
    async changeDanmuColor(color) {
      try {
        // if (color != 'FFFFFF' && !that.isvip) {
        //   that.YS.gotoVip(1, 'open', 'mustvip');
        //   return;
        // }
        that.danmu.color = color;
      } catch (error) {
        console.log(error.message);
      }
    },
    //点击弹幕样式
    clidanmuSytle() {
      that.danmuMenu = false;
    },
    //获取排行榜
    async getlead() {
      try {
        if (that.videolist) {
          let data = await that.api.user.api_videorank({
            parent_category_id: that.videolist.parent_category_id,
          });

          that.play_video_rank_list = data.video_rank_list;
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    // 切换集
    clivideoinfo(id) {
      that.YS.routetogo({
        path: '/play',
        query: { video_id: this.videonews.video_id, lid: id },
      });
      //this.routerRefresh(); //调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
    },
    //切换集数列表
    cliSettitle(index) {
      that.video_fragmen_act = index;
      that.ltindex = index;
      // that.YS.actgo('pst_list', 'pst_list-act', index);
    },
    // 点击收藏视频按钮
    clifavorite() {
      if (that.$store.state.islogin) {
        //已登录
        that.gofavorite();
      } else {
        //未登录
        that.$store.commit('addloginBefore', () => {
          that.gofavorite();
        });
        that.YS.gotoVip({}, 'open', 'loginReg'); //打开弹窗
        that.$refs.top.TOvercode(); //获取验证码
      }
    },
    //收藏视频
    async gofavorite() {
      try {
        if (that.in_favorite) {
          // 已经收藏的情况
          //发送取消收藏
          await that.api.user.api_videodeleteFavorite({
            video_id: that.videonews.video_id,
          });
          that.in_favorite = 0;
          that.videolist.favorite -= 1;
        } else {
          // 未收藏的情况
          //发送收藏视频
          await that.api.user.api_videofavorite({ video_id: that.videonews.video_id });
          that.in_favorite = 1;
          that.videolist.favorite += 1;
        }
      } catch (error) {
        that.$refs.top.usepubilDialog({ title: error.message, code: false });
      }
    },
    //评论列表
    async videocomment() {
      try {
        let data = await that.api.user.api_videocomment({
          video_id: that.videonews.video_id,
        });
        that.video_comment_total = data.video_comment_total;
        that.video_comment_list = data.video_comment_list;
      } catch (error) {
        that.$refs.top.usepubilDialog({ title: error.message, code: false });
      }
    },
    //点赞点踩
    videoevaluate(type) {
      // if (that.YS.iseffective()) {
      if (that.$store.state.islogin) {
        //已登录
        that.getvaluate(type);
      } else {
        //未登录
        that.$store.commit('addloginBefore', () => {
          that.getvaluate(type);
        });
        that.YS.gotoVip({}, 'open', 'loginReg'); //打开弹窗
        that.$refs.top.TOvercode(); //获取验证码
      }
    },
    //点赞点踩接口
    async getvaluate(type) {
      try {
        if (!that.in_evaluate) {
          let data = await that.api.user.api_videoevaluate({
            video_id: that.videonews.video_id,
            evaluate: type,
          });

          if (type > 0) {
            //点赞
            that.videolist.appreciate += 1;
            that.evaluate_type = 1;
          } else {
            //反之点踩
            that.videolist.depreciate += 1;
            that.evaluate_type = -1;
          }
        } else {
          that.$refs.top.usepubilDialog({ title: error.message, code: false });
        }
      } catch (error) {
        that.$refs.top.usepubilDialog({ title: error.message, code: false });
      }
    },
    //发表评论
    async videopostComment() {
      try {
        await that.api.user.api_videopostComment({
          video_id: that.videonews.video_id,
          content: that.textcontent,
        });

        that.$refs.top.usepubilDialog({ title: '评价成功', code: true });
        that.videocomment();
      } catch (error) {
        that.$refs.top.usepubilDialog({ title: error.message, code: false });
      }
    },
    // 获取广告
    async adall(symbols) {
      try {
        if (JSON.stringify(that.guanggaolist) == '{}') {
          let data = await that.api.user.api_adall({ symbols: symbols });
          if (data) {
            that.guanggaolist = data;
            //存储至本地
            that.YS.savestore('guanggaolist_play', data, 600);
          }
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    //点击广告
    cliguanggao(type) {
      // that.guanggaolist[type][0];
      window.open(that.guanggaolist[type][0].url);
    },
    //点击视频详情
    clickxq() {
      that.p_d = !that.p_d;
    },
    //点击错误反馈
    cliplayerror() {
      if (that.$store.state.islogin) {
        //已登录
        that.YS.gotoVip(1, 'open', 'playerror');
      } else {
        //未登录
        that.$store.commit('addloginBefore', () => {
          that.YS.gotoVip(1, 'open', 'playerror');
        });
        that.YS.gotoVip({}, 'open', 'loginReg'); //打开弹窗
        that.$refs.top.TOvercode(); //获取验证码
      }
    },
    //相关剧集点击
    cliLeadrank(video_id) {
      that.YS.routetogo({ path: '/play', query: { video_id: video_id } }, true);
      //如果存在播放器dp则为播放页,刷新路由
      if (that.dp) {
        // that.routerRefresh();
        that.YS.routetogo({ path: '/play', query: { video_id: video_id } }, true);
      }
    },
    //需要开通会员 点击开通会员
    climustvip() {
      // clearInterval(that.set2);
      if (that.$store.state.islogin) {
        //已经登录

        that.YS.gotoVip(1, 'close', 'mustvip');
        that.YS.gotoVip(1, 'open', 'vipReg');
      } else {
        //添加方法到登录前置
        that.$store.commit('addloginBefore', () => {
          that.YS.gotoVip(1, 'open', 'vipReg');
        });
        that.$refs.top.usepubilDialog({ title: '请先登录后开通vip', code: false });
        that.YS.gotoVip(1, 'close', 'mustvip');
        that.YS.gotoVip(1, 'open', 'loginReg');
        that.$refs.top.TOvercode(); //获取验证码
      }
    },
    //需要登录 点击登录
    climustlogin() {
      that.YS.gotoVip(1, 'close', 'mustlogin');
      that.YS.gotoVip(1, 'open', 'loginReg');
      that.$refs.top.TOvercode(); //获取验证码
    },
    //切换清晰度失败时复位
    resque() {
      setTimeout(() => {
        var qu = document.getElementsByClassName('art-controls-right')[0].childNodes;
        Array.from(qu).forEach((e) => {
          if (JSON.stringify(e.classList).indexOf('art-control-quality') > 1) {
            e.childNodes[0].textContent = that.qua[that.videonews.quality];
            // e.childNodes[1].childNodes[that.videonews.quality - 1];
          }
        });
      });
    },
    // 切换下一集
    nextvideo() {
      sessionStorage.removeItem('repeatedly');
      that.repeatedly = false;
      var nowid = parseInt(this.videonews.video_fragment_id);
      var nextvideoindex;
      that.video_fragment_list.forEach((e, i) => {
        if (e.id == nowid) {
          nextvideoindex = i;
        }
      });
      //判断是否有下一集
      if (that.video_fragment_list[nextvideoindex + 1]) {
        var nextvideoid = that.video_fragment_list[nextvideoindex + 1].id;
        that.YS.routetogo({
          path: '/play',
          query: { video_id: this.videonews.video_id, lid: nextvideoid },
        });
      } else if (that.lastListCode && nextvideoindex) {
        that.lastListCode = false;
        that.$refs.top.usepubilDialog({ title: '没有下一集了', code: false });
      }

      //this.routerRefresh(); //调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
    },
    changequalityTest() {},
    //获取清晰度url/验证清晰度权限
    //改变时
    async changequality(e, i, aaa, dp) {
      try {
        // that.qua.indexOf(e.innerHTML)>=3,是找不到当前清晰度会变成null，indexof则为-1，
        if (!that.$store.state.islogin && that.qua.indexOf(e.textContent) >= 2) {
          //没登陆时候
          // if (that.qua.indexOf(e.textContent) >= 3) {
          //   //选中超清蓝光
          //   clearInterval(that.set2);
          //   that.YS.gotoVip(1, 'open', 'mustvip');
          //   that.resque();
          // } else
          if (that.qua.indexOf(e.textContent) == 2) {
            //选中标清高清
            // clearInterval(that.set2);
            that.YS.gotoVip(1, 'open', 'mustlogin');
            that.resque();
          }
        }
        // else if (
        //   Date.parse(new Date()).toString().substr(0, 10) >
        //     that.$store.state.user.vip_expired_time &&
        //   that.qua.indexOf(e.textContent) >= 3
        // ) {
        //   clearInterval(that.set2);
        //   that.YS.gotoVip(1, 'open', 'mustvip');
        //   that.resque();
        // }
        else if (!that.$store.state.islogin && that.qua.indexOf(e.textContent) == 2) {
          that.YS.gotoVip(1, 'open', 'mustlogin');
          that.resque();
        } else {
          //这里成功通过校验
          // 一、样式选择添加
          aaa.forEach((item) => {
            item.classList.remove('clear_act');
          });
          aaa[i].classList.add('clear_act');
          // 二、匹配后端的清晰度标识，（后端是从1开始）
          // that.videonews.quality = parseInt(e.className) + 1;
          //给每一个清晰度按钮添加class，跟据class判断按钮的下标顺序
          that.videonews.quality = e.className.split('q__')[1];
          dp.storage.set('qialityloc', that.videonews.quality);
          // 三、向后端发送接口，获取清晰度的url信息
          let data = await that.api.user.api_videosource(that.videonews);
          // debugger;
          // that.options.video.quality[that.videonews.quality - 1] = data.video_soruce;
          // that.options.video.quality[that.videonews.quality - 1].url = data.video_soruce.url;
          // that.options.video.quality[that.videonews.quality - 1].subtitle =
          //   data.video_soruce.subtitle;
          var actname = that.qua[that.videonews.quality];
          if (that.options.video.quality) {
            that.options.video.quality.forEach((e, i) => {
              if (e.name == actname) {
                that.qact = i;
                e = data.video_soruce;
                e.url = data.video_soruce.url;
                e.subtitle = data.video_soruce.subtitle;
                e.name = that.qua[data.video_soruce.quality];
                that.url = data.video_soruce.url;
                that.videonews.quality = i + 1;
                that.qualityName = that.qua[data.video_soruce.quality];
              }
            });
          }

          that.url = data.video_soruce.url; //artplayer
          //这里需要手动调用切换清晰度方法（修改了插件，删除了插件中的方法）
          // -1从后端的清晰度下标转化成dplayer插件的清晰度下标

          dp.player.switchQuality(
            that.url,
            that.options.video.quality[that.videonews.quality - 1].name,
          );

          // dp.subtitle.switch(data.video_soruce.subtitle);

          // dp.switch(that.url);
          // that.resque();
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    //开局获取并检查校验清晰度
    getAndCheckquer() {
      var qialityloc = that.locitem ? that.locitem.qialityloc : '';
      //开局获取清晰度并校验
      if (!!qialityloc) {
        //存在本地存储时，以地址栏参数为优先,地址栏不指定参数则调用本地存储参数
        if (this.$route.query.quality) {
          this.videonews.quality = this.$route.query.quality;
        } else {
          this.videonews.quality = qialityloc;
        }
      } else {
        //不存在本地存储清晰度时，优先获取地址栏参数

        this.videonews.quality = this.$route.query.quality ? this.$route.query.quality : 1;
      }
      //清晰度权限校验
      if (this.videonews.quality > 1) {
        //未登录时
        if (!that.$store.state.islogin) {
          this.videonews.quality = 1;
          // if (this.videonews.quality > 2) {
          //   clearInterval(that.set2);
          //   that.YS.gotoVip(1, 'open', 'mustvip');
          // }
          if (this.videonews.quality == 2) {
            that.YS.gotoVip(1, 'open', 'mustlogin');
          }
        }
        // else {
        //   //登录时
        //   if (this.videonews.quality > 2 && !that.isvip) {
        //     this.videonews.quality = 1;
        //     clearInterval(that.set2);
        //     that.YS.gotoVip(1, 'open', 'mustvip');
        //     if (localStorage.getItem('artplayer_settings')) {
        //       var localitem = JSON.parse(localStorage.getItem('artplayer_settings'));
        //       if (localitem.qialityloc) {
        //         localitem.qialityloc = this.videonews.quality;
        //         localStorage.setItem('artplayer_settings', JSON.stringify(localitem));
        //       }
        //     }
        //   }
        // }
      }
    },
    //跳过片头
    jumptitle(dp) {
      if (!!that.jumpover && !!that.video_soruce.front_duration && that.jumpover != 0) {
        if (
          that.video_soruce.front_duration &&
          dp.player.currentTime < that.video_soruce.front_duration &&
          !that.video_soruce.front_start_duration
        ) {
          //没有片头开始参数时
          dp.player.seek = that.video_soruce.front_duration;
          dp.notice.show = '尊敬的VIP会员，已经为您自动跳过片头。';
        }
      } else {
        //存在跳转参数时跳转（地址栏）
        that.videonews.seek && (dp.player.seek = that.videonews.seek);
      }
    },
    //跳过片尾
    jumptail(dp) {
      if (
        (that.video_soruce.tail_duration || that.video_soruce.front_duration) &&
        that.jumpover != 0
      ) {
        var videodom = document.getElementsByClassName('art-video')[0];
        videodom.addEventListener('timeupdate', () => {
          var timeDisplay = Math.floor(dp.player.currentTime);
          // console.log(timeDisplay);
          //跳过片头
          if (that.jumpover) {
            if (
              that.video_soruce.front_start_duration &&
              timeDisplay > that.video_soruce.front_start_duration &&
              timeDisplay < that.video_soruce.front_duration - 3
            ) {
              dp.player.seek = that.video_soruce.front_duration;
              dp.notice.show = '尊敬的VIP会员，已经为您自动跳过片头。';
            }
            //存在片尾end时，跳过片尾
            if (
              that.video_soruce.tail_duration &&
              that.video_soruce.tail_end_duration &&
              timeDisplay > that.video_soruce.tail_duration &&
              timeDisplay < that.video_soruce.tail_end_duration
            ) {
              dp.player.seek = that.video_soruce.tail_end_duration;
              dp.notice.show = '尊敬的VIP会员，已经为您自动跳过片尾。';
            }
            //接上，之后自动下一集
            if (
              that.video_soruce.tail_duration &&
              that.video_soruce.tail_end_duration > 0 &&
              timeDisplay > that.video_soruce.tail_end_duration &&
              timeDisplay > Math.floor(dp.player.duration) - 5
            ) {
              dp.notice.show = '尊敬的VIP会员，即将结束播放，5秒后将自动为您播放下一集';
            }
            if (
              that.video_soruce.tail_end_duration > 0 &&
              timeDisplay > that.video_soruce.tail_end_duration &&
              timeDisplay + 1 > Math.floor(dp.player.duration)
            ) {
              dp.notice.show = '尊敬的VIP会员，为您跳转下一集';
              that.nextvideo();
            }
            //不存在片尾end时，播放下一集
            if (
              that.video_soruce.tail_duration &&
              !that.video_soruce.tail_end_duration &&
              timeDisplay > that.video_soruce.tail_duration - 5
            ) {
              dp.notice.show = '尊敬的VIP会员，即将结束播放，5秒后将自动为您播放下一集';
            }
            if (
              that.video_soruce.tail_duration &&
              !that.video_soruce.tail_end_duration &&
              timeDisplay + 1 > that.video_soruce.tail_duration
            ) {
              // dp.notice.show = '到达片尾，为您跳转下一集';
              that.video_soruce.tail_duration = 9999999999;
              that.nextvideo();
            }
            if (dp.player.currentTime + 1 > dp.player.duration) {
              // dp.notice.show = '到达片尾，为您跳转下一集';
              that.video_soruce.tail_duration = 9999999999;
              that.nextvideo();
            }
          }
        });
      }
    },
    //自动下一集
    autoNextSet(dp) {
      var nowid = parseInt(this.videonews.video_fragment_id);
      var nextvideoindex;
      that.video_fragment_list.forEach((e, i) => {
        if (e.id == nowid) {
          nextvideoindex = i;
        }
      });
      if (that.video_fragment_list && that.video_fragment_list[nextvideoindex + 1]) {
        var videodom = document.getElementsByClassName('art-video')[0];
        videodom.addEventListener('timeupdate', () => {
          // console.log(timeDisplay);
          if (dp.player.currentTime > that.dp.player.duration - 8 && that.dp.player.duration > 1) {
            dp.notice.show = '视频即将结束，即将为您跳转下一集';
          }
          if (dp.player.currentTime > that.dp.player.duration - 1 && that.dp.player.duration > 1) {
            // dp.notice.show = '到达片尾，为您跳转下一集';
            that.nextvideo();
          }
        });
      }
    },
    //改变片头片尾按钮
    changejump(dp) {
      var tiaovalue = document.getElementById('tiaoinput');
      // if (!that.isvip) {
      //   tiaovalue.value = '0';
      //   tiaovalue.classList.remove('buleback');
      //   clearInterval(that.set2);
      //   return that.YS.gotoVip(1, 'open', 'mustvip');
      // }

      //点击时未改变值
      that.jumpover = !that.jumpover;
      if (that.jumpover) {
        tiaovalue.value = '1';
        tiaovalue.classList.add('buleback');
      } else {
        tiaovalue.value = '0';
        tiaovalue.classList.remove('buleback');
      }
      dp.storage.set('jumpover', that.jumpover);

      //执行跳过片尾方法
      if (that.jumpover) {
        //开启跳过片头,片尾
        that.jumptitle(dp);
        that.jumptail(dp);
        dp.notice.show = '开启跳过片头片尾功能。';
      } else {
        //关闭跳过片头片尾
        //******************************* */
        that.video_soruce.tail_duration = 999999999999999999999;
        dp.notice.show = '关闭跳过片头片尾功能。';
      }
    },
    //改变片头片尾按钮
    changejump2(dp, type) {
      let jumpDom = document
        .getElementsByClassName('art-control-jumpTail')[0]
        .getElementsByClassName('art-selector-value')[0];
      // if (!that.isvip) {
      //   jumpDom.innerText = '跳片头尾：关闭';
      //   clearInterval(that.set2);
      //   return that.YS.gotoVip(1, 'open', 'mustvip');
      // }

      //点击时未改变值
      that.jumpover = type;
      if (that.jumpover) {
        jumpDom.innerText = '跳片头尾：开启';
      } else {
        jumpDom.innerText = '跳片头尾：关闭';
      }
      dp.storage.set('jumpover', that.jumpover);

      //执行跳过片尾方法
      if (that.jumpover) {
        //开启跳过片头,片尾
        that.jumptitle(dp);
        that.jumptail(dp);
        dp.notice.show = '开启跳过片头片尾功能。';
      } else {
        //关闭跳过片头片尾
        //******************************* */
        that.video_soruce.tail_duration = 999999999999999999999;
        dp.notice.show = '关闭跳过片头片尾功能。';
      }
    },
    //开关字幕按钮
    changezimu(dp) {
      var zimuinput = document.getElementById('zimuinput');
      var playvideo = document.getElementsByClassName('art-video-player')[0];
      //点击时未改变值
      //取相反值

      that.jumpzimu = !that.jumpzimu;
      if (that.jumpzimu) {
        zimuinput.value = 1;
        zimuinput.classList.add('buleback');
        playvideo.classList.add('art-subtitle-show');
        dp.notice.show = '开启字幕显示。';
      } else {
        zimuinput.value = 0;
        zimuinput.classList.remove('buleback');
        playvideo.classList.remove('art-subtitle-show');
        dp.notice.show = '关闭字幕显示。';
      }

      dp.storage.set('jumpzimu', that.jumpzimu);
    },
    //多选字幕选中样式
    changesubtitles() {
      var subtitlesdiv = document.getElementById('zimubuttonid');
      subtitlesdiv.childNodes.forEach((ele) => {
        if (ele.dataset.symbol == that.actsubcode) {
          ele.classList.add('bulebackbor');
        } else {
          ele.classList.remove('bulebackbor');
        }
      });
    },
    // 滚动条距离页面顶部的距离
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let playhei = document.getElementsByClassName('art-video')[0].offsetHeight;
      let playdoms = document.getElementsByClassName('art-video')[0];
      if (scrollTop > playhei) {
        if (that.dp.player.playing == true) {
          that.dp.player.pip = playdoms;
        }
        // that.dp.option.pip = true;
      }
      // else {
      //   if (that.dp.player.playing == false) {
      //     that.dp.player.pip = playdoms;
      //   }
      // }
    },

    //添加class和插入dom
    domClassAdd() {
      setTimeout(() => {
        var aaa = document.getElementsByClassName('art-control-quality')[0].childNodes[1]
          .childNodes;

        // 给清晰度按钮遍历添加事件
        aaa.forEach((e, i) => {
          aaa[i].addEventListener('click', function () {
            that.changequality(e, i, aaa, that.dp); //获取清晰度url/验证清晰度权限
          });

          // ); //给他们各自添加 “1080P”这种class名,没有则把1080p替换成在qua的下标
          aaa[i].classList.add('q__' + that.qua.indexOf(e.textContent) + 'q__'); //给他们各自添加 “1080P”这种class名,没有则把1080p替换成在qua的下标
          aaa[i].classList.add('clear_' + that.qua.indexOf(e.textContent));
        });

        //页面进去后判断给添加选中样式
        that.listqua.forEach((e, i) => {
          if (e.name == that.qua[parseInt(that.videonews.quality)]) {
            aaa[i].classList.add('clear_act');
          }
        });
        // if (aaa[that.videonews.quality]) {

        // }

        //添加下一集点击事件
        var bbb = document.getElementsByClassName('art-control-nextP')[0];
        bbb.addEventListener('click', () => {
          that.nextvideo();
        });

        // var ccc = document.getElementById('tiaoinput');
        // ccc.addEventListener('touchend', () => {
        //   that.changejump(dp);
        // });

        var ddd = document.getElementById('zimuinput');
        ddd.addEventListener('touchend', () => {
          that.changezimu(dp);
        });

        // var ggg = document.getElementsByClassName('art-progress');
        // ggg[0].addEventListener('touchmove', () => {

        // });

        try {
          if (localStorage.getItem('artplayer_settings')) {
            var localP = JSON.parse(localStorage.getItem('artplayer_settings'));

            if (localP.backRate && localP.backRate > 0) {
              that.dp.player.playbackRate = localP.backRate;
              that.changeBeishu(localP.backRate_index); //倍数选中样式
            }
          }
        } catch (error) {
          console.log(error.message);
        }
        if (that.isiPad) {
          var eee = document
            .getElementsByClassName('artplayer-app')[0]
            .getElementsByClassName('art-video')[0];
          eee.addEventListener('click', () => {
            // e.stopPropagation();
            that.dp.player.toggle = true;
          });
        }
        // var eee = document.getElementsByClassName('art-video')[0];
        // eee.addEventListener('click', () => {
        //   // e.stopPropagation();
        //   that.dp.player.toggle = true;
        // });
        // var fff = document.getElementsByClassName('art-mask')[0];
        // fff.addEventListener('click', () => {
        //   that.dp.player.play = true;
        // });

        // -----------------插入vip小icon----------------------------
        // var div1 = document.getElementsByClassName('clear_3')[0];
        // var br = document.createElement('div');
        // br.className = 'clear_vip';
        // if (div1) {
        //   div1.appendChild(br);
        // }

        // var div2 = document.getElementsByClassName('clear_4')[0];
        // var br2 = document.createElement('div');
        // br2.className = 'clear_vip';
        // if (div2) {
        //   div2.appendChild(br2);
        // }
        // var eee = document.getElementsByClassName('art-control-backR')[0]
        // eee.
        // -----------------插入vip小icon----------------------------
      }, 1000);
    },
    // 点击评论
    toComment() {
      var hei = that.YS.getElementTop(document.getElementById('commentTitle'));
      if (hei) {
        window.scrollTo({
          top: hei - 80,
          behavior: 'smooth',
        });
      }
    },
    //切换倍数选中样式
    async changeBeishu(index) {
      try {
        var beishuList = document.getElementsByClassName('art-control-backR')[0].childNodes[1];
        beishuList.childNodes.forEach((e) => {
          e.classList.remove('beishu_act');
        });
        beishuList.childNodes[index].classList.add('beishu_act');
      } catch (error) {
        console.log(error.message);
      }
    },
    openAutoErrorReport() {
      // var qwe = 0;
      // that.set2 = setInterval(() => {
      //   qwe += 1;
      //   if (qwe >= 15) {
      //     that.autoErrorReport = true;
      //     that.cliplayerror();
      //     clearInterval(that.set2); //倒计时报错反馈
      //   }
      // }, 1001); //倒计时报错反馈
    },
    getInputFocus(event) {
      // console.log("event", event);
      event.currentTarget.select();
    },
    //管理员模式设置片头
    async setHead() {
      try {
        if (that.loadCli) return false;
        that.loadCli = true;
        var temHT = {};
        temHT.headtime = Math.floor(that.dp.player.currentTime);
        temHT.duration = Math.floor(that.dp.player.duration);
        temHT.id = that.tem_id;
        temHT.quality = that.videonews.quality;
        temHT.video_fragment_id = that.videonews.video_fragment_id;
        temHT.video_id = that.videonews.video_id;

        that.video_soruce.front_duration; //片头
      } catch {
        alert('等加载完');
      } finally {
        that.loadCli = false;
      }
    },
    //管理员模式设置片尾
    async setTail() {
      try {
        if (that.loadCli) return false;
        that.loadCli = true;
        var temHT = {};
        temHT.tailtime = Math.floor(that.dp.player.currentTime);
        temHT.duration = Math.floor(that.dp.player.duration);
        temHT.id = that.tem_id;
        temHT.quality = that.videonews.quality;
        temHT.video_fragment_id = that.videonews.video_fragment_id;
        temHT.video_id = that.videonews.video_id;

        that.video_soruce.tail_duration; //片尾
      } catch {
        alert('等加载完');
      } finally {
        that.loadCli = false;
      }
    },

    async puaseTime() {
      try {
        if (
          that.islogin &&
          !(
            Math.floor(that.dp.player.currentTime) > that.puaseCurrrent - 5 &&
            Math.floor(that.dp.player.currentTime) < that.puaseCurrrent + 5
          )
        ) {
          let stoplist = {};
          stoplist.video_id = that.videonews.video_id;
          stoplist.video_fragment_id = that.videonews.video_fragment_id;
          // stoplist.quality = that.videonews.quality;
          stoplist.duration = Math.floor(that.dp.player.duration);
          stoplist.elapsed = Math.floor(that.dp.player.currentTime);
          that.api.user.api_userupdateHistory(stoplist);
          that.puaseCurrrent = stoplist.elapsed;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //点击关闭广告
    advertisingClose() {
      that.advertising_ready = false;
      clearInterval(that.set_ad);
      that.advertising_video.destroy();
      that.advertising_loading = true;
      that.dp.player.play = true;
    },
    // updateStats() {
    //   console.log();
    //   that.textp2p =
    //     'P2P正在为您加速' +
    //     (that._totalP2PDownloaded / 1024).toFixed(2) +
    //     'MB 已分享' +
    //     (that._totalP2PUploaded / 1024).toFixed(2) +
    //     'MB' +
    //     ' 连接节点' +
    //     that._peerNum +
    //     '个';
    // },
  },
};
</script>
<style lang="scss">
@media screen and (min-width: 1601px) and (max-width: 1795px) {
  .play_body_content {
    width: 1367px !important;
  }
  .artplayer-app,
  .artplayer-app0 {
    height: 843px !important;
    width: 1500px !important;
  }
  .pbr_top {
    width: 336px !important;
    height: 357px !important;
  }
  .pbr_bottom {
    width: 336px !important;
    height: 235px !important;
  }
  .play_other {
    width: 1400px !important;
  }
  .bottombody {
    width: 1500px !important;
  }
  .film {
    min-height: 574px !important;
  }
  .play_other-left {
    min-width: 1000px;
  }
}
.artplayer-app0 {
  height: 669px;
  width: 1190px;
  position: absolute;
  top: 0;
}
.artplayer-app {
  // height: 667px;
  height: 900px;
  width: 1600px;
  position: relative;
  // margin-left: -54px !important;
}
.art-video-player {
  width: 100% !important;
  height: 100% !important;
}
// .clear_1 {
// }
.clear_act {
  border: 1px solid #ff8800 !important;
  // margin: 0 6px;
  border-radius: 4px;
  font-size: 12px;
}
.clear_3 {
  position: relative;
}
.clear_4 {
  position: relative;
}

// .quert_box {
//   padding: 10px;
// }
.art-video-player .art-control-selector .art-selector-list {
  width: 110px !important;
  padding: 10px 6px !important;
  z-index: 99;
}
.art-control-nextP {
  cursor: pointer;
  height: 36px;
  width: 36px;
  mask-image: url('../assets/img/play_next.svg') !important;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 36% !important;
  background-color: white !important;
}
// .art-control-control10 {
//   mask-image: url('../assets/img/play_next.svg') !important;
//   mask-position: center;
//   mask-repeat: no-repeat;
//   mask-size: 36% !important;
//   background-color: white !important;
// }
.topout {
  display: none;
}
.artplayer-app {
  .art-notice {
    top: 75%;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
  }
  .art-notice-inner {
    height: 41px !important;
    border-radius: 21px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 5px 20px !important;
    transition: all 0.3s;
  }
}
// .art-control-backR .art-selector-value {
//   width: 62px !important;
//   display: flex;
//   align-items: center;
//   margin-left: 5px;
//   background-image: url('../assets/img/video_vip.png');
//   background-position: right;
//   background-repeat: no-repeat;
//   justify-content: flex-end;
//   padding-right: 27px;
// }
.art-control-jumpTail .art-selector-list :nth-child(1),
.art-control-backR .art-selector-list :nth-child(1) {
  background-image: url('../assets/img/video_vip.png');
  background-position: right;
  background-repeat: no-repeat;
  justify-content: flex-end;
}
.art-video-player .art-settings .art-setting-inner .art-setting-body .art-setting {
  border-bottom: none;
}
.art-setting-subtitleOffset {
  display: none;
}
// .art-mask {
//   pointer-events: auto !important;
//   cursor: pointer;
// }
// .art-setting-radio {
//   margin-left: -2px;
//   .art-radio-item button {
//     width: 70px !important;
//     border: 1px solid #ffffff !important;
//     background: #333333 !important;
//     border-radius: 4px;
//   }
//   .current button {
//     border: 1px solid #149bec !important;
//     background: #149bec !important;
//   }
// }
.art-control-quality .art-selector-item {
  margin-top: 25px !important;
  border: 1px solid #ff8800;
  border-radius: 4px !important;
  overflow: visible !important;
  color: #ff8800;
  position: relative !important;
}
.art-contextmenu-version {
  display: none !important;
}
// .q__1q__ {
//   border: 1px solid #149bec !important ;
//   color: white;
//   background: ;
// }
.q__1q__::before {
  content: '会员';
  top: -23px;
  left: -10px;
  position: absolute !important;
  font-size: 12px;
  transform: scale(0.8);
  color: white;
}
.q__2q__::before {
  content: '会员';
  top: -23px;
  left: -3px;
  position: absolute !important;
  font-size: 12px;
  transform: scale(0.8);
  color: #ff8800;
  font-weight: 800;
}
.q__3q__::before {
  content: '会员';
  top: -23px;
  left: -3px;
  position: absolute !important;
  font-size: 12px;
  transform: scale(0.8);
  color: #ff8800;
  font-weight: 800;
}
.q__4q__::before {
  content: '会员';
  top: -23px;
  left: -3px;
  position: absolute !important;
  font-size: 12px;
  transform: scale(0.8);
  color: #ff8800;
  font-weight: 800;
}
// .clear_act::before {
//   color: white !important;
// }

.right-body .rb-list:nth-child(-n + 3) {
  .rb-list-title a {
    color: #149bec;
  }
}
.beishu_act {
  background: #2bb2ff;
}
.art-controls-left {
  height: 30px;
  display: flex;
  align-items: flex-start;
}
.art-controls-right {
  height: 30px;
  display: flex;
  align-items: flex-start;
}
.art-settings .art-backdrop-filter {
  height: calc(100% - 45px) !important;
}

.art-video-player
  .art-bottom
  .art-progress
  .art-control-progress
  .art-control-progress-inner
  .art-progress-played,
.art-video-player
  .art-bottom
  .art-progress
  .art-control-progress:hover
  .art-control-progress-inner
  .art-progress-indicator,
.art-video-player
  .art-bottom
  .art-progress
  .art-control-progress:hover
  .art-control-progress-inner
  .art-progress-tip {
  background: rgb(20, 155, 236) !important;
}
.art-control-selector,
.art-control-quality,
.art-control-jumpTail {
  padding: 0 10px !important;
}
.topout_pc {
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-left: 20px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}
.pleaseLogin_pc {
  position: absolute;
  // left: calc(50vw - 60px);
  left: calc(50% - 60px);
  top: calc(50% - 20px);
  height: 40px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: linear-gradient(134deg, #6acbe9 0%, #149bec 100%);
  color: white;
  font-weight: bold;
  cursor: pointer;
}
</style>
